import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {useParams} from "react-router-dom";

import lockIconSrc from './img/lock.svg';

/**
 * Convert floating number to 5 digits(2 decimals).
 * @link https://digiproduct-squad.monday.com/boards/2379852222/pulses/2735928854
 * @param {number} num eg. 119120
 * @returns {string} eg. 119.12K
 */
export const getFormattedBalance = (num) => {
  if (Math.abs(num) < 0.001) return String(num);

  const options = {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4, // helps override rounding to floor
  };
  const decimals = (Math.abs(num) < 100) ? 3 : 2;
  const nf = (new Intl.NumberFormat('en-US', options));
  // check Intl notation support
  if (!nf.resolvedOptions().hasOwnProperty('notation')) {
    return String(num);
  }

  return nf.formatToParts(num).map(({ type, value }) => {
    if (type === 'fraction') return value.substring(0, decimals);

    return value;
  }).join('');
};

const BalanceStrip = ({ balance }) => {
    const { id } = useParams();
    const cl = id !== undefined ? " inside" : "";

    const bg = process.env.REACT_APP_BRAND_CTA_BG_Color;
    const items = balance.map((item, index) => {
      let percentage = ((item.free - item.locked) * 100) / item.free;

      return (
        <div className="banace_item_wrap" key={index}>
          <div style={{ width: 72, height: 72 }} className="banace_item">
            <CircularProgressbarWithChildren
              value={percentage}
              counterClockwise
              styles={buildStyles({
                textColor: "#EBEBEB",
                pathColor: bg,
                trailColor: "#707070",
                strokeLinecap: "butt",
                rotation: 0.1,
              })}
            >
              <div
                style={{
                  fontSize: 12,
                  color: "#C4C4C4",
                  fontFamily: "Ubuntu",
                  fontStyle: "italic",
                }}
              >
                {item.asset}
              </div>
              <div
                style={{
                  fontSize: 16,
                  color: "#EBEBEB",
                  fontFamily: "Ubuntu",
                  marginTop: -2,
                }}
              >
                {getFormattedBalance(item.free).toLocaleLowerCase()}
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="chart_label">
            <img src={lockIconSrc} alt="" />
            <span>{getFormattedBalance(item.locked).toLocaleLowerCase()}</span>
          </div>
        </div>
      );
    });

    return <div className={"balance_strip"+cl}>{items}</div>;
};
export default BalanceStrip;
