import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: {
    type: null,
    params: null,
  },
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    modalShown(state, action) {
      const { payload: { type, ...params } } = action;
      state.modal.type = type;
      state.modal.params = params;
    },
    modalClosed(state) {
      state.modal = initialState.modal;
    },
  },
});

export const {
  modalShown,
  modalClosed,
} = slice.actions;

export const MODAL_CANCEL_ORDER_TYPE = 'cancel_order';
export const MODAL_PLACE_ORDER_TYPE = 'place_order'; // mobile only

export const selectModal = (state) => state.ui.modal;

export default slice.reducer;
