import React from "react";

import styled from "styled-components";
import { DateTime } from "luxon";

const DeleteActiveItem = styled('button')`
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    color: #0D0D0D;
    border: none;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background: ${props => props.bgah}
    }
}
`

/**
 * Get formatted date string.
 * @param {DateTime} luxonDate Valid Luxon DateTime object.
 * @param {object} dateOptions
 * @param {object} timeOptions
 * @returns {string|null} eg. Mon, Jan 3 | 10:20 pm
 */
export const getFormattedDate = (
    luxonDate,
    dateOptions = {
        weekday: "short",
        month: "short",
        day: "numeric",
    },
    timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    },
) => {
    if (!DateTime.isDateTime(luxonDate) || luxonDate.isInvalid) return null;
    return `${luxonDate.toLocaleString(dateOptions)} | ${luxonDate.toLocaleString(timeOptions)}`;
};

const ActiveOrderAsset = ({
    orders,
    onCancelClick,
 }) => {
    const ordersItems = orders.map((item, index) => {
        const { orderId, instrument, createdAt, expiryDate = null } = item;

        return (
            <tr key={index}>
                <td>{getFormattedDate(DateTime.fromMillis(createdAt))}</td>
                <td>
                    <span className={item.side}>{item.side}</span>
                </td>
                <td>{item.price}</td>
                <td>{item.remainingOpenQuantity}</td>
                <td>{item.filledQuantity}</td>
                <td>{item.filledPrice}</td>
                <td>
                    {item.timeInForce}
                    {expiryDate && ` ${getFormattedDate(DateTime.fromSeconds(expiryDate))}`}
                </td>

                <td>
                    <DeleteActiveItem
                        bgah={process.env.REACT_APP_BRAND_CTA_BG_Color}
                        onClick={() => onCancelClick({ orderId, instrument })}
                    >
                        &ndash;
                    </DeleteActiveItem>
                </td>
            </tr>
        );
    });

    return(
        <>{ordersItems}</>
    )
}

export default ActiveOrderAsset;