import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/AuthSlice';
import balanceReducer from './slices/BalanceSlice';
import historyReducer from './slices/HistorySlice';
import instrumentsReducer from './slices/InstrumentsSlice';
import tradingReducer from './slices/TradingSlice';
import socketsReducer from './slices/SocketsSlice';
import uiReducer from './slices/UiSlice';
import SocketsMiddleware from './SocketsMiddleware';

export default configureStore({
  reducer: {
    auth: authReducer,
    balance: balanceReducer,
    instruments: instrumentsReducer,
    history: historyReducer,
    trading: tradingReducer,
    sockets: socketsReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(SocketsMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
});
