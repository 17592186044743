import React, {useState} from "react";
import styled from "styled-components";

import LogoutButton from "./Logout";

const UserName = styled("span")`
  font-size: 14px;
  line-height: 28px;
  color: #FFFFFF;
  margin-right: 6px;
  transition: all 0.4s ease;
  @media( max-width: 430px){
    font-size: 1.2vh;
  }
`

const Header = ({
  username,
  children,
}) => {
  const [isOpen, setToggle] = useState();
  const toggle = () => setToggle(!isOpen);

  return (
    <header>
      <div className="logo_container">
        <div className="logo">
          <img src={process.env.REACT_APP_BRAND_LOGO} alt="logo" />
        </div>
      </div>
      {children}
      <div className="menu_container">
        <div className="user_wrapp">
          <div className="user_name" onClick={toggle}>
            <UserName>
              Welcome <span>{username}</span>
            </UserName>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="first_path"
                d="M12.1359 2.25042C6.65243 2.17636 2.17587 6.65292 2.24993 12.1364C2.32305 17.3953 6.60509 21.6773 11.864 21.7504C17.3484 21.8254 21.824 17.3489 21.749 11.8654C21.6768 6.60557 17.3948 2.32354 12.1359 2.25042ZM18.0618 17.5903C18.0431 17.6105 18.0202 17.6263 17.9948 17.6367C17.9693 17.647 17.9418 17.6516 17.9144 17.6502C17.8869 17.6488 17.8601 17.6413 17.8358 17.6284C17.8116 17.6154 17.7905 17.5973 17.774 17.5753C17.3547 17.0267 16.8413 16.5571 16.2576 16.1882C15.0641 15.4223 13.552 15.0004 11.9999 15.0004C10.4479 15.0004 8.93571 15.4223 7.74227 16.1882C7.15862 16.5569 6.64516 17.0264 6.22587 17.5748C6.20939 17.5968 6.18828 17.6149 6.16402 17.6279C6.13976 17.6409 6.11294 17.6483 6.08548 17.6497C6.05801 17.6512 6.03057 17.6465 6.00509 17.6362C5.97961 17.6258 5.95673 17.61 5.93805 17.5898C4.56259 16.105 3.78344 14.1648 3.74993 12.141C3.67352 7.57964 7.41649 3.76167 11.9798 3.75042C16.5431 3.73917 20.2499 7.44464 20.2499 12.0004C20.2515 14.073 19.4699 16.0696 18.0618 17.5903Z"
                fill={process.env.REACT_APP_BRAND_CTA_BG_Color}
              />
              <path
                id="second_path"
                d="M12 6.75C11.0756 6.75 10.2398 7.09641 9.6459 7.72594C9.05199 8.35547 8.75527 9.22594 8.82231 10.1602C8.95824 12 10.3837 13.5 12 13.5C13.6162 13.5 15.0389 12 15.1776 10.1606C15.247 9.23531 14.9526 8.37281 14.3489 7.73156C13.7526 7.09875 12.9182 6.75 12 6.75Z"
                fill={process.env.REACT_APP_BRAND_CTA_BG_Color}
              />
              <path
                id="fird_path"
                d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM9.64594 7.72594C10.2398 7.09641 11.0756 6.75 12 6.75C12.9244 6.75 13.7527 7.09875 14.3489 7.73156C14.9531 8.37281 15.247 9.23437 15.1777 10.1606C15.0389 12 13.6139 13.5 12 13.5C10.3861 13.5 8.95828 12 8.82234 10.1602C8.75344 9.22641 9.04687 8.36203 9.64594 7.72594ZM12 20.25C10.8987 20.2507 9.80838 20.0303 8.79382 19.6018C7.77927 19.1732 6.86109 18.5453 6.09375 17.7553C6.53323 17.1286 7.0932 16.5957 7.74094 16.1878C8.93578 15.4219 10.448 15 12 15C13.552 15 15.0642 15.4219 16.2577 16.1878C16.9059 16.5955 17.4664 17.1284 17.9062 17.7553C17.139 18.5454 16.2208 19.1734 15.2062 19.6019C14.1917 20.0304 13.1014 20.2508 12 20.25V20.25Z"
                fill={process.env.REACT_APP_BRAND_CTA_BG_Color}
              />
            </svg>
          </div>
        </div>
        {isOpen && <LogoutButton />}
      </div>
    </header>
  );
};

export default Header;
