import React from "react";
import Multiselect from "multiselect-react-dropdown";
import EmptyIcon from "../../TrandingScreen/EmptyIcon/EmptyIcon";
import {Scrollbars} from "react-custom-scrollbars-2";

const OredersTradesHistory = ({
    tradesHistory,
}) => {
    let tradersHistoryItems;

    if(tradesHistory.length !== 0) {
        tradersHistoryItems = tradesHistory.map((item, index) => {
            let d = new Date(item.timestamp);
            let options = {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour12: true,
            };
            let str = d.toLocaleString("en-US", options);
            let time = d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
            let strSplit = str.split(",");
            return (
                <tr key={index}>
                    <td>{strSplit[0] + "," + strSplit[1] + " | " + time}</td>
                    <td>
                        <span className="instr">{item.instrument}</span>
                    </td>
                    <td>
                        <span className={item.side}>{item.side}</span>
                    </td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                </tr>
            );
        });
    }

    return(
        <div className="tab_wrapp">
            <div className="content_table">
                <Scrollbars style={{ height: 'calc(70vh - 50px)'}}
                            renderTrackVertical={({ style, ...props }) =>
                                <div {...props} style={{ ...style, width: 4,
                                    right: 0,
                                    bottom: 2,
                                    top: 2,
                                    borderRadius: 3 }}/>
                            }
                            renderView={props => <div {...props} className="view"/>}>
                    <table className="table history-table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Instrument</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tradersHistoryItems !== undefined ? tradersHistoryItems : <EmptyIcon></EmptyIcon>}
                        </tbody>
                    </table>
                </Scrollbars>
            </div>
        </div>
    );
}

export default OredersTradesHistory;

