import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

const ButtonLogin = styled("button")`
    background: ${props=> props.bgl};
    border-radius: 30px;
    height: 40px;
    width: 100%;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border: 0;
    text-align: center;
    color: ${props=> props.colB};
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
linear-gradient(0deg, ${props => props.bgl}, ${props => props.bgl});
  }
`

const LoginContainer = styled("div")`
  background-image: url(${props => props.urlB});
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <LoginContainer urlB={process.env.REACT_APP_BRAND_IMAGE}>
      {/* <div className="overlay"></div> */}
      <div className="login_modal">
        <div className="modal_logo">
          <img src={process.env.REACT_APP_BRAND_LOGO} alt="" />
        </div>
        <div className="modal_title">
          <h2>Welcome</h2>
        </div>
        <div className="modal_content">
          <p>
            Please click Login to continue
          </p>
        </div>
        <div className="modal_btn_wrapp">
          <ButtonLogin className="login_btn" onClick={() => loginWithRedirect()} bgl={process.env.REACT_APP_BRAND_CTA_BG_Color} colB={process.env.REACT_APP_BRAND_CTA_Text_Color}>
            Log In
          </ButtonLogin>
        </div>
      </div>
    </LoginContainer>
  );
};

export default LoginButton;
