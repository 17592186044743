import { createSlice, createSelector } from '@reduxjs/toolkit';

import { streamsStateChanged, streamDataPushed } from './SocketsSlice';

const dataState = {
  error: null,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
};

const dataFilters = {
  dateFrom: 'last_week',
  offset: 0,
  limit: 50,
};

const initialState = {
  // Orders & Trades → Active Orders
  activeOrders: {
    sid: null,
    feedbackSids: [],
    data: [],
    count: 0,
    ...dataState,
    filters: {
      offset: 0,
      limit: 50,
    },
  },
  // Orders & Trades → Orders History
  ordersHistory: {
    sid: null,
    data: [],
    count: 0,
    ...dataState,
    filters: {
      ...dataFilters,
    },
  },
  // Orders & Trades → Trades
  tradesHistory: {
    sid: null,
    data: [],
    count: 0,
    ...dataState,
    filters: {
      ...dataFilters,
    },
  },
};

const defaultResponseHandler = (
  data,
  payload,
  onNewDataCallback = null,
) => {
  if (typeof onNewDataCallback !== 'function') {
    onNewDataCallback = () => {
      data.data = payload.d.orders || payload.d.trades;
      data.count = payload.d.count;
    };
  }

  if (payload?.sig === 3) {
    // stream closed, clean data
    data.data = [];
    data.isLoading = true;
  } else if (payload?.sig === 1) {
    // skip item
    data.isLoading = false;
  } else if (payload?.d && payload?.sig !== 2) {
    // answer with new data
    onNewDataCallback();
  } else {
    // error or unexpected answer
    data.isError = true;
    data.isLoading = false;
    data.error = {
      data: {
        code: payload?.errorType,
        message: payload?.d.errorMessage || `Unexpected response from ${payload?.sid} SID`,
      },
    };
  }
};

/**
 * All dynamic data appeared at History Screen and Trading with Orders History tab.
 * Orders History, Trades History
 */
const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    dataFilterChanged(state, { payload }) {
      const { dataType, ...filters } = payload;
      switch (dataType) {
        case 'activeOrders':
        case 'ordersHistory':
        case 'tradesHistory':
          Object.keys(filters).forEach(key => {
            state[dataType].filters[key] = filters[key];
          });
          break;
        default:
          // ignore
      }
    },
  },
  extraReducers: {
    [streamsStateChanged.toString()]: (state, { payload }) => {
      // map just created stream to slice property
      // don't listen push actions from old stream anymore
      const { created } = payload;
      created.forEach(({ sliceDataKey, sid }) => {
        switch (sliceDataKey) {
          case 'history.activeOrders':
            state.activeOrders.sid = sid;
            state.activeOrders.data = [];
            break;
          case 'history.ordersHistory':
            state.ordersHistory.sid = sid;
            state.ordersHistory.data = [];
            break;
          case 'history.tradesHistory':
            state.tradesHistory.sid = sid;
            state.tradesHistory.data = [];
            break;
          case 'trading.activeOrders':
            if (!state.activeOrders.feedbackSids.includes(sid)) {
              state.activeOrders.feedbackSids.push(sid);
            }
            break;
          default:
            // ignore
        }
      });
    },
    [streamDataPushed.toString()]: (state, { payload }) => {
      let sliceData = null;
      switch (payload?.sid) {
        case null:
          break;
        case state.activeOrders.sid:
          sliceData = state.activeOrders;
          defaultResponseHandler(sliceData, payload);
          break;
        case state.ordersHistory.sid:
          sliceData = state.ordersHistory;
          defaultResponseHandler(sliceData, payload);
          break;
        case state.tradesHistory.sid:
          sliceData = state.tradesHistory;
          defaultResponseHandler(sliceData, payload);
          break;
        default:
          // ignore
      }

      // handle also active orders from trading
      if (state.activeOrders.feedbackSids.includes(payload?.sid) && payload?.d?.messageType === 'Cancelled') {
        state.activeOrders.data = state.activeOrders.data.filter(({ orderId }) => orderId !== payload?.d.orderId);
      }
    },
  },
});

export const {
  dataFilterChanged,
} = slice.actions;

export default slice.reducer;

export const selectActiveOrders = createSelector(
  (state) => state.history.activeOrders,
  ({ data, count, filters: { offset, limit } }) => ({
    data,
    count,
    currentPage: offset / limit + 1,
    totalPages: Math.ceil(count / limit),
    itemsPerPage: limit,
  }),
);

export const selectOrdersHistory = createSelector(
  (state) => state.history.ordersHistory,
  ({ data, count, filters: { dateFrom, offset, limit } }) => ({
    data,
    count,
    dateFrom,
    currentPage: offset / limit + 1,
    totalPages: Math.ceil(count / limit),
    itemsPerPage: limit,
  }),
);

export const selectTradesHistory = createSelector(
  (state) => state.history.tradesHistory,
  ({ data, count, filters: { dateFrom, offset, limit } }) => ({
    data,
    count,
    dateFrom,
    currentPage: offset / limit + 1,
    totalPages: Math.ceil(count / limit),
    itemsPerPage: limit,
  }),
);
