import { createSelector } from '@reduxjs/toolkit';

import { selectLatestNotification } from './slices/SocketsSlice';
import { selectBalance } from './slices/BalanceSlice';
import {
  selectAllInstruments,
  makeSelectInstrumentsOrderBy,
  selectInstrumentsSymbols,
  selectCurrentInstrumentSymbol,
} from './slices/InstrumentsSlice';
import {
  selectLiveTrades,
  selectOrderBook,
  selectInstrumentOrdersHistory,
  makeSelectLiveTradesWithSort,
  makeSelectInstrumentActiveOrdersWithSort,
} from './slices/TradingSlice';

import {
  selectActiveOrders,
  selectOrdersHistory,
  selectTradesHistory,
} from './slices/HistorySlice';
import { selectModal } from './slices/UiSlice';

const selectUsername = (state) => state.auth.username;

/**
 * Select instrument by id.
 * @param {String} id uuid
 * @returns {callback} to use with useSelector
 */
const selectCurrentInstrument = createSelector(
  selectAllInstruments,
  selectCurrentInstrumentSymbol,
  selectLiveTrades,
  (instruments, symbol, liveTrades) => {
    const item = instruments.find(element => symbol === element.symbol);
    if (item) {
      const [latestBidPrice] = liveTrades.reduce((prev, current) => {
        // there is unix timestamp by index 3
        // [1356, 1.03, 0, 1651168454033]
        // higher value means new
        if (current?.[3] > prev?.[3]) {
          return current;
        }

        return prev;
      }, [item?.bidPrice, 0, 0, 0]);// use original bidPrice as default value

      return { ...item, bidPrice: latestBidPrice };
    }
    return item;
  },
);


export {
  selectUsername,
  selectBalance,
  selectInstrumentsSymbols,
  makeSelectInstrumentsOrderBy,
  selectCurrentInstrument,
  selectCurrentInstrumentSymbol,
  selectLiveTrades,
  makeSelectLiveTradesWithSort,
  makeSelectInstrumentActiveOrdersWithSort,
  selectOrderBook,
  selectInstrumentOrdersHistory,
  selectLatestNotification,
  selectActiveOrders,
  selectOrdersHistory,
  selectTradesHistory,
  selectModal,
};
