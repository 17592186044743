import React, {useState} from "react";
import styled from "styled-components";

const TIFDownContainer = styled("div")`
  width: 80px;
  border: none;
  padding: 11px;
  color: #F6F6F6;
  outline: none;
  position: relative;
  border-radius: 13px;
  background: #414244;
`;

const TIFDownHeader = styled("div")`
    width: 100%;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
`;

const TIFDownListContainer = styled("div")`
    top: 40px;
    left: 0px;
    width: 100px;
    position: absolute;
`;

const TIFDownList = styled("ul")`
  padding: 8px;
  margin: 0;
  background: #414244;
  border: 1px solid #414244;
  box-sizing: border-box;
  border-radius: 4px;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
`;

const ListItem = styled("li")`
  list-style: none;
    color: ${props => {
    if(props.activeitem === 'GTC'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else if(props.activeitem === 'GTD'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else if(props.activeitem === 'IOC'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else if(props.activeitem === 'FOK'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else {
        return '#EBEBEB';
    }
}};
  padding: 8px;
  cursor: pointer;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  &:hover{
    background: #262726;
    padding: 7px;
    border: 1px solid #414244;
    border-radius: 3px;
  }
`;

const TifDropDown = ({
    selected,
    options = [],
    onChildrenClick,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);

    return (
        <TIFDownContainer>
            <TIFDownHeader onClick={toggling} className="drop">{selected}</TIFDownHeader>
            {isOpen && (
                <TIFDownListContainer>
                    <TIFDownList>
                        {options.map(({ label, value }) => (
                            <ListItem
                                key={value}
                                activeitem={value === selected ? value : ''}
                                onClick={() => {
                                    onChildrenClick(value);
                                    toggling();
                                }}
                            >
                                {label}
                            </ListItem>
                        ))}
                    </TIFDownList>
                </TIFDownListContainer>
            )}
        </TIFDownContainer>
    );
};

export default TifDropDown;
