import React, {useEffect, useState} from "react";

const Notification = ({ messNotification }) => {
    const [typeMess, setTypeMess] = useState('')
    const [mess, setMess] = useState('');
    const [showTop, setShowTop] = useState('-55px');

    useEffect(()=>{
        if (messNotification?.messageType === "Added") {
            setShowTop('-5px');
            setTypeMess('added');
            setMess(`Order added, ${messNotification.side} ${messNotification.remainingOpenQuantity} units of ${messNotification.instrument} at ${messNotification.price}`.replace(' at undefined', ''));
            setTimeout(() => {
                setShowTop('-55px');
            }, 10000);
        }else if(messNotification?.messageType === "Executed"){
            setShowTop('-5px');
            setTypeMess('added');
            setMess(`Order executed, ${messNotification.side} ${messNotification.lastFilledQuantity} units of ${messNotification.instrument} at ${messNotification.price}`.replace(' at undefined', ''));
            setTimeout(() => {
                setShowTop('-55px');
            }, 10000);
        }else if(messNotification?.messageType === "Cancelled"){
            setShowTop('-5px');
            setTypeMess('cancelled');
            setMess(`Order Cancelled, ${messNotification.side} ${messNotification.cancelledQuantity} units of ${messNotification.instrument}`);
            setTimeout(() => {
                setShowTop('-55px');
            }, 10000);
        }else if(messNotification?.messageType === "Rejected") {
            setShowTop('-5px');
            setTypeMess('error');
            setMess(`Failed: ${messNotification.errorMessage}`);
            setTimeout(() => {
                setShowTop('-55px');
            }, 10000);
        }else if(messNotification?.errorCode){
            setShowTop('-5px');
            setTypeMess('error');
            setMess(`Failed: ${messNotification.errorMessage}`);
            setTimeout(() => {
                setShowTop('-55px');
            }, 10000);
        }
    },[messNotification])

    return(
        <div className="notification_wrap" style={{top: showTop}}>
            <div className="notification_message">
                <span className={typeMess}>{mess}</span>
                <span className="close" onClick={()=>{setShowTop('-55px')}}></span>
            </div>
        </div>
    )
}

export default Notification;