import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  function out (){
      logout({ returnTo: window.location.origin });
  }
  return (
    <div className="logoutWrap">
      <button onClick={out}>
        <img src="./img/exit.svg" alt="" />
        Log Out
      </button>
    </div>
  );
};

export default LogoutButton;
