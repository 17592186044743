import React from "react";
import EmptyIcon from "../EmptyIcon/EmptyIcon";

const OrderBookBidAsk = ({ orderBooks }) => {
    let bid = [];
    let ask = [];
    let bidAmount = [];
    let askAmount = [];
    let resPrAmountB = [];
    let resPrAmountA = [];
    let ResTab;

    if(orderBooks.length) {
        /** @TODO heavy computation, need to move to selectors */
        orderBooks.find((item, index) => {
            item.bids.map((itm, c) => {
                bid.push(itm[0]);
                bidAmount.push(itm[1])
            })
            let resultBid = bidAmount.reduce((sum, current) => sum + current, 0);
            bidAmount.map((item) => {
                let t = item / resultBid * 100;
                resPrAmountB.push(Math.floor(t * 100) / 100);
            })
        });
        orderBooks.find((item, index) => {
            item.asks.map((itm, c) => {
                ask.push(itm[0]);
                askAmount.push(itm[1]);
            })

            let resultAsk = askAmount.reduce((sum, current) => sum + current, 0);
            askAmount.map((item) => {
                let p = item / resultAsk * 100;
                resPrAmountA.push(Math.floor(p * 100) / 100);
            })
        });

        if (bid.length !== 0) {
            ResTab = bid.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="td_gradient_ask"><span className="gradient_bid" style={{width: resPrAmountB[index] + '%'}}></span>{bidAmount[index]}
                        </td>
                        <td>{bid[index]}</td>
                        <td>{ask[index]}</td>
                        <td className="td_gradient_ask"><span className="gradient_ask" style={{width: resPrAmountA[index] + '%'}}></span>{askAmount[index]}
                        </td>
                    </tr>
                );
            })
        } else {
            ResTab = ask.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="td_gradient_ask"><span className="gradient_bid"
                                                              style={{width: resPrAmountB[index] + '%'}}></span>{bidAmount[index]}
                        </td>
                        <td>{bid[index]}</td>
                        <td>{ask[index]}</td>
                        <td className="td_gradient_ask"><span className="gradient_ask"
                                                              style={{width: resPrAmountA[index] + '%'}}></span>{askAmount[index]}
                        </td>
                    </tr>
                );
            })
        }
    }
    return<>{ResTab}</>;
}

export default OrderBookBidAsk;