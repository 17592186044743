import React from 'react';
import DropDownRows from "./OredersTrades/DropDownRows";

const Pagination = ({
    itemsPerPage,
    currentPage,
    totalPages,
    count,
    onFirstClick,
    onPrevClick,
    onNextClick,
    onLastClick,
    onRowsChange,
}) => (
    <>
        <span className="text-paginator">
                    <DropDownRows
                        value={itemsPerPage}
                        onChange={( rowsValue ) => {
                            onRowsChange(rowsValue);
                        }}
                    ></DropDownRows>
            <div className="shows_paginator">
            Showing {Math.max((currentPage - 1) * itemsPerPage + 1, 1)}
            -
                {Math.min(currentPage * itemsPerPage, count)} / {count} Rows</div>
        </span>
        <span className="btn-paginator">
            <button
                type="button"
                aria-label="first"
                disabled={currentPage <= 1}
                onClick={onFirstClick}
            >
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.9472 11.2278C12.7307 11.3392 12.4701 11.3202 12.272 11.1787L5.272 6.17873C5.10118 6.05672 4.9998 5.85972 4.9998 5.6498C4.99981 5.43988 5.10118 5.24289 5.272 5.12087L12.272 0.120875C12.4701 -0.0206452 12.7307 -0.0395699 12.9472 0.0718431C13.1637 0.183256 13.2998 0.406321 13.2998 0.649802L13.2998 10.6498C13.2998 10.8933 13.1637 11.1163 12.9472 11.2278ZM11.9998 9.38673L11.9998 1.91288L6.76811 5.6498L11.9998 9.38673ZM-0.000193575 0.149801L1.29981 0.149801L1.2998 11.1498L-0.000194536 11.1498L-0.000193575 0.149801Z" fill="#F6F6F6"/>
                </svg>
            </button>
            <button
                type="button"
                aria-label="previous"
                disabled={currentPage <= 1}
                onClick={onPrevClick}
            >
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.08013 0.96967C6.37303 1.26256 6.37303 1.73744 6.08013 2.03033L2.11046 6L6.08013 9.96967C6.37303 10.2626 6.37303 10.7374 6.08013 11.0303C5.78724 11.3232 5.31237 11.3232 5.01947 11.0303L0.519474 6.53033C0.226581 6.23744 0.226581 5.76256 0.519474 5.46967L5.01947 0.96967C5.31237 0.676777 5.78724 0.676777 6.08013 0.96967Z" fill="#F6F6F6"/>
                </svg>
            </button>
            <button
                type="button"
                aria-label="next"
                disabled={currentPage >= totalPages}
                onClick={onNextClick}
            >
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.519475 11.0303C0.226581 10.7374 0.226581 10.2626 0.519475 9.96967L4.48914 6L0.519475 2.03033C0.226581 1.73744 0.226581 1.26256 0.519475 0.96967C0.812368 0.676777 1.28724 0.676777 1.58013 0.96967L6.08013 5.46967C6.37303 5.76256 6.37303 6.23744 6.08013 6.53033L1.58013 11.0303C1.28724 11.3232 0.812368 11.3232 0.519475 11.0303Z" fill="#F6F6F6"/>
                </svg>
            </button>
            <button
                type="button"
                aria-label="last"
                disabled={currentPage >= totalPages}
                onClick={onLastClick}
            >
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.352572 0.0720434C0.569068 -0.0393694 0.829675 -0.0204453 1.0278 0.121076L8.0278 5.12108C8.19862 5.24309 8.3 5.44008 8.3 5.65C8.3 5.85992 8.19862 6.05692 8.0278 6.17893L1.0278 11.1789C0.829675 11.3204 0.569068 11.3394 0.352572 11.228C0.136076 11.1165 0 10.8935 0 10.65V0.650002C0 0.40652 0.136076 0.183456 0.352572 0.0720434ZM1.3 1.91308V9.38693L6.5317 5.65L1.3 1.91308ZM13.3 11.15H12V0.150002H13.3V11.15Z" fill="#F6F6F6"/>
                </svg>
            </button>
        </span>
    </>
);

export default Pagination;
