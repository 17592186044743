import React, {useEffect, useMemo, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

import Header from "../Header";
import OredersActiveOrders from "./OredersActiveOrders/OredersActiveOrders";
import OredersOrdersHistory from "./OredersOrdersHistory/OredersOrdersHistory";
import OredersTradesHistory from "./OredersTradesHistory/OredersTradesHistory";
import Footer from "../Footer";
import DateFromFilter from '../DateFromFilter';

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { modalShown, MODAL_CANCEL_ORDER_TYPE } from '../store/slices/UiSlice';
import { dataFilterChanged } from '../store/slices/HistorySlice';
import {
  dataSubscriptionsChanged,
  MARKET_DATA_SESSION_SUBSCRIPTION,
  TRADING_SESSION_SUBSCRIPTION,
  ACTIVE_ORDERS_SUBSCRIPTION,
  ORDERS_HISTORY_SUBSCRIPTION,
  TRADES_HISTORY_SUBSCRIPTION,
} from '../store/slices/SocketsSlice';
import {
  selectActiveOrders,
  selectOrdersHistory,
  selectTradesHistory,
} from '../store/selectors';
import Pagination from '../Pagination';

const OrderTrades = ({ children }) => {
  const { user } = useAuth0();
  const [selectedTab, setSelectedTab] = useState('activeOrders');
  const dispatch = useDispatch();
  const activeOrders = useSelector(selectActiveOrders);
  const ordersHistory = useSelector(selectOrdersHistory);
  const tradesHistory = useSelector(selectTradesHistory);

  // compute dateFrom selector value
  const { dateFrom, currentPage, totalPages, count, itemsPerPage } = useMemo(() => {
    switch (selectedTab) {
      case 'activeOrders':
        return { ...activeOrders };
      case 'ordersHistory':
        return { ...ordersHistory };
      case 'tradesHistory':
        return { ...tradesHistory };
      default:
        return {};
    }
  }, [
    selectedTab,
    activeOrders,
    ordersHistory,
    tradesHistory,
  ]);

  useEffect(() => {
    dispatch(dataSubscriptionsChanged([
        MARKET_DATA_SESSION_SUBSCRIPTION,
        TRADING_SESSION_SUBSCRIPTION,
        ACTIVE_ORDERS_SUBSCRIPTION,
    ]));
  }, []);

  return (
        <div className="ot_wrapp">
          <Header username={user.name}>
          </Header>
          <div className="order_traders_wrap">
            <div className="footer_top">
              <Link to="/">
                Orders & Trades
                <img src="../img/btn_arrow_2.svg" alt="" />
              </Link>
              <div className="footerBTNTop"></div>
              <div className="footerBTN_hoverTop"></div>
            </div>
            <div className="order_traders_tabs_wrap">
              {/* nested popup */}
              {children}
              {/* nested popup */}
              <Tabs
                onSelect={(index) => {
                  const subs = [
                    MARKET_DATA_SESSION_SUBSCRIPTION,
                    TRADING_SESSION_SUBSCRIPTION,
                  ];
                  let tab = 'activeOrders';
                  switch (index) {
                    case 0:
                      subs.push(ACTIVE_ORDERS_SUBSCRIPTION);
                      tab = 'activeOrders';
                      break;
                    case 1:
                      subs.push(ORDERS_HISTORY_SUBSCRIPTION);
                      tab = 'ordersHistory';
                      break;
                    case 2:
                      subs.push(TRADES_HISTORY_SUBSCRIPTION);
                      tab = 'tradesHistory';
                      break;
                    default:
                      // never happens
                  }
                  dispatch(dataSubscriptionsChanged(subs));
                  setSelectedTab(tab);
                }}
              >
                <TabList>
                  <Tab>
                    <svg
                        width="11"
                        height="15"
                        viewBox="0 0 11 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 0.994141C0.895431 0.994141 0 1.88957 0 2.99414V12.9941C0 14.0987 0.895431 14.9941 2 14.9941H9C10.1046 14.9941 11 14.0987 11 12.9941V2.99414C11 1.88957 10.1046 0.994141 9 0.994141H2ZM3 3.49414C2.72386 3.49414 2.5 3.718 2.5 3.99414C2.5 4.27028 2.72386 4.49414 3 4.49414H8C8.27614 4.49414 8.5 4.27028 8.5 3.99414C8.5 3.718 8.27614 3.49414 8 3.49414H3ZM3 5.99414C2.72386 5.99414 2.5 6.218 2.5 6.49414C2.5 6.77028 2.72386 6.99414 3 6.99414H8C8.27614 6.99414 8.5 6.77028 8.5 6.49414C8.5 6.218 8.27614 5.99414 8 5.99414H3ZM3 8.49414C2.72386 8.49414 2.5 8.718 2.5 8.99414C2.5 9.27028 2.72386 9.49414 3 9.49414H5.14286C5.419 9.49414 5.64286 9.27028 5.64286 8.99414C5.64286 8.718 5.419 8.49414 5.14286 8.49414H3Z"
                      />
                    </svg>
                    <span>
                      Active Orders
                    </span>
                  </Tab>
                  <Tab>
                    <svg
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1184_12268)">
                        <g clipPath="url(#clip1_1184_12268)">
                          <path d="M10.5 10.6504C8.92951 10.6504 7.65625 11.9237 7.65625 13.4941C7.65625 15.0646 8.92951 16.3379 10.5 16.3379C12.0705 16.3379 13.3438 15.0646 13.3438 13.4941C13.3438 11.9237 12.0705 10.6504 10.5 10.6504ZM11.8125 13.9316H10.5C10.442 13.9316 10.3863 13.9086 10.3453 13.8676C10.3043 13.8265 10.2812 13.7709 10.2812 13.7129V11.7441C10.2812 11.6861 10.3043 11.6305 10.3453 11.5895C10.3863 11.5484 10.442 11.5254 10.5 11.5254C10.558 11.5254 10.6137 11.5484 10.6547 11.5895C10.6957 11.6305 10.7188 11.6861 10.7188 11.7441V13.4941H11.8125C11.8705 13.4941 11.9262 13.5172 11.9672 13.5582C12.0082 13.5992 12.0312 13.6549 12.0312 13.7129C12.0312 13.7709 12.0082 13.8265 11.9672 13.8676C11.9262 13.9086 11.8705 13.9316 11.8125 13.9316Z" />
                        </g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 2.99414C0 1.88957 0.895431 0.994141 2 0.994141H9C10.1046 0.994141 11 1.88957 11 2.99414V10.0296C10.8367 10.0062 10.6698 9.99414 10.5 9.99414C8.567 9.99414 7 11.5611 7 13.4941C7 14.0309 7.12085 14.5395 7.33682 14.9941H2C0.895431 14.9941 0 14.0987 0 12.9941V2.99414ZM2.5 3.99414C2.5 3.718 2.72386 3.49414 3 3.49414H8C8.27614 3.49414 8.5 3.718 8.5 3.99414C8.5 4.27028 8.27614 4.49414 8 4.49414H3C2.72386 4.49414 2.5 4.27028 2.5 3.99414ZM2.5 6.49414C2.5 6.218 2.72386 5.99414 3 5.99414H8C8.27614 5.99414 8.5 6.218 8.5 6.49414C8.5 6.77028 8.27614 6.99414 8 6.99414H3C2.72386 6.99414 2.5 6.77028 2.5 6.49414ZM2.5 8.99414C2.5 8.718 2.72386 8.49414 3 8.49414H5.14286C5.419 8.49414 5.64286 8.718 5.64286 8.99414C5.64286 9.27028 5.419 9.49414 5.14286 9.49414H3C2.72386 9.49414 2.5 9.27028 2.5 8.99414Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1184_12268">
                          <rect
                              width="14"
                              height="16"
                              fill="white"
                              transform="translate(0 0.994141)"
                          />
                        </clipPath>
                        <clipPath id="clip1_1184_12268">
                          <rect
                              width="7"
                              height="7"
                              fill="white"
                              transform="translate(7 9.99414)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>
                      Orders History
                    </span>
                  </Tab>
                  <Tab>
                    <svg
                        width="14"
                        height="17"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1184_12280)">
                        <g clipPath="url(#clip1_1184_12280)">
                          <path d="M10.5 10.6504C8.92951 10.6504 7.65625 11.9237 7.65625 13.4941C7.65625 15.0646 8.92951 16.3379 10.5 16.3379C12.0705 16.3379 13.3438 15.0646 13.3438 13.4941C13.3438 11.9237 12.0705 10.6504 10.5 10.6504ZM11.8125 13.9316H10.5C10.442 13.9316 10.3863 13.9086 10.3453 13.8676C10.3043 13.8265 10.2812 13.7709 10.2812 13.7129V11.7441C10.2812 11.6861 10.3043 11.6305 10.3453 11.5895C10.3863 11.5484 10.442 11.5254 10.5 11.5254C10.558 11.5254 10.6137 11.5484 10.6547 11.5895C10.6957 11.6305 10.7188 11.6861 10.7188 11.7441V13.4941H11.8125C11.8705 13.4941 11.9262 13.5172 11.9672 13.5582C12.0082 13.5992 12.0312 13.6549 12.0312 13.7129C12.0312 13.7709 12.0082 13.8265 11.9672 13.8676C11.9262 13.9086 11.8705 13.9316 11.8125 13.9316Z" />
                        </g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 2.99414C0 1.88957 0.895431 0.994141 2 0.994141H9C10.1046 0.994141 11 1.88957 11 2.99414V10.0296C10.8367 10.0062 10.6698 9.99414 10.5 9.99414C8.567 9.99414 7 11.5611 7 13.4941C7 14.0309 7.12085 14.5395 7.33682 14.9941H2C0.895431 14.9941 0 14.0987 0 12.9941V2.99414ZM8.24807 3.56002C8.48783 3.69702 8.57113 4.00245 8.43412 4.24221L6.64841 7.36721L6.37651 7.84303L5.92755 7.52876L4.63369 6.62305L3.45474 9.20202C3.33993 9.45317 3.04326 9.56369 2.79212 9.44888C2.54098 9.33407 2.43045 9.03741 2.54526 8.78626L3.97383 5.66126L4.22345 5.11523L4.7153 5.45952L6.05206 6.39525L7.56588 3.74607C7.70288 3.50631 8.00831 3.42301 8.24807 3.56002Z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1184_12280">
                          <rect
                              width="14"
                              height="16"
                              fill="white"
                              transform="translate(0 0.994141)"
                          />
                        </clipPath>
                        <clipPath id="clip1_1184_12280">
                          <rect
                              width="7"
                              height="7"
                              fill="white"
                              transform="translate(7 9.99414)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>
                      Trades History
                    </span>
                  </Tab>
                  <span className="orderTradesTab">
                    {/* date from filter */}
                    {['ordersHistory', 'tradesHistory'].includes(selectedTab) && (
                        <DateFromFilter
                            value={dateFrom}
                            onChange={( textValue ) => {
                              dispatch(dataFilterChanged({
                                dataType: selectedTab,
                                dateFrom: (textValue !== 'custom') ? textValue : null,
                                offset: 0,
                              }));
                            }}
                        />
                    )}
                  </span>
                </TabList>
                <TabPanel>
                  <OredersActiveOrders
                    activeOrders={activeOrders.data}
                    onCancelClick={({ orderId, instrument }) => {
                      dispatch(modalShown({
                          type: MODAL_CANCEL_ORDER_TYPE,
                          orderId,
                          instrument,
                      }));
                    }}
                  >
                  </OredersActiveOrders>
                </TabPanel>
                <TabPanel>
                  <OredersOrdersHistory
                    ordersHistory={ordersHistory.data}
                  >
                  </OredersOrdersHistory>
                </TabPanel>
                <TabPanel>
                  <OredersTradesHistory
                    tradesHistory={tradesHistory.data}
                  >
                  </OredersTradesHistory>
                </TabPanel>
              </Tabs>
              <div className="paginator_wrapp">
                {/* pagination  */}
                {activeOrders.data.length !== 0 || ordersHistory.data.length !== 0 || tradesHistory.data.length !== 0 ?
                <Pagination
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    count={count}
                    onFirstClick={() => {
                      dispatch(dataFilterChanged({
                        dataType: selectedTab,
                        offset: 0,
                      }));
                    }}
                    onPrevClick={() => {
                      dispatch(dataFilterChanged({
                        dataType: selectedTab,
                        offset: (currentPage - 2) * itemsPerPage,
                      }));
                    }}
                    onNextClick={() => {
                      dispatch(dataFilterChanged({
                        dataType: selectedTab,
                        offset: currentPage * itemsPerPage,
                      }));
                    }}
                    onLastClick={() => {
                      dispatch(dataFilterChanged({
                        dataType: selectedTab,
                        offset: (totalPages - 1) * itemsPerPage,
                      }));
                    }}
                    onRowsChange={(rows) => {
                      dispatch(dataFilterChanged({
                        dataType: selectedTab,
                        offset: 0,
                        limit: parseInt(rows),
                      }));
                    }}
                /> : ''}</div>
            </div>
          </div>
          <Footer />
        </div>
  );
};

export default OrderTrades;
