import React, {useEffect, useMemo} from "react";
import {useAuth0} from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import BalanceStrip from "../Balance";
import Header from "../Header";
import Asset from "../Asset";
import Footer from "../Footer";
import { Scrollbars } from "react-custom-scrollbars-2";
import styled from "styled-components";

import {
    dataSubscriptionsChanged,
    INSTRUMENTS_SUBSCRIPTION,
    TRADING_SESSION_SUBSCRIPTION,
    MARKET_DATA_SESSION_SUBSCRIPTION,
    LIGHT_TICKERS_SUBSCRIPTION,
    BALANCE_SUBSCRIPTION,
} from '../store/slices/SocketsSlice';
import {
    selectBalance,
    makeSelectInstrumentsOrderBy,
} from '../store/selectors';

const MainContainer = styled("div")`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: top center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const MainDashboard = ({ children }) => {
    const { user } = useAuth0();
    const balanceData = useSelector(selectBalance);
    const selectInstruments = useMemo(() => makeSelectInstrumentsOrderBy({ field: 'symbol', dir: 'asc' }), []);
    const instruments = useSelector(selectInstruments);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dataSubscriptionsChanged([
            INSTRUMENTS_SUBSCRIPTION,
            TRADING_SESSION_SUBSCRIPTION,
            MARKET_DATA_SESSION_SUBSCRIPTION,
            LIGHT_TICKERS_SUBSCRIPTION,
            BALANCE_SUBSCRIPTION,
        ]));
    }, []);

    return (
        <MainContainer url={process.env.REACT_APP_BRAND_IMAGE}>
            <div className="wrap_main">
                <div className="overlay"></div>
                <Header username={user.name}>
                </Header>
                <Scrollbars style={{ height: 107 }} className="wrapBalance">
                    <BalanceStrip balance={balanceData} />
                </Scrollbars>
                {/* nested popup */}
                {children}
                {/* nested popup */}
                <Scrollbars style={{ height: 'calc(100vh - 275px)'}}>
                    <div className="main_title">
                        <h1>{process.env.REACT_APP_BRAND_TITLE}</h1>
                        <p>{process.env.REACT_APP_BRAND_TEXT}</p>
                    </div>
                    <div className="main_traiders_frames">
                        <Scrollbars style={{ height: '100vh', minHeight: '400px' }}>
                            <div className="traiders_frames_wrap">
                                <Asset instruments={instruments} />
                            </div>
                        </Scrollbars>
                    </div>
                </Scrollbars>
            </div>
            <div className="footer_down">
                <Link to="/orders-trades">
                    <img src="../img/btn_arrow_2.svg" alt="" />
                    Orders & Trades
                </Link>
                <div className="footerBTN"></div>
                <div className="footerBTN_hover"></div>
            </div>
            <Footer />
        </MainContainer>
    );
};
export default MainDashboard;
