import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  username: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    successfulLogin(state, action) {
      state.token = action.payload?.token;
      state.username = action.payload?.username;
    },
  },
});

export const {
  successfulLogin,
} = slice.actions;

export default slice.reducer;
