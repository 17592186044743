import React from "react";

const bgc = process.env.REACT_APP_BRAND_CTA_BG_Color;

const OrderHistoryAsset = ({ ordersHistory }) => {
    const ordersHistoryItem = ordersHistory.map((item, index) => {
        let d = new Date(item.createdAt);
        let options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour12: true,
        };
        let str = d.toLocaleString("en-US", options);
        let time = d.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        let strSplit = str.split(",");
        let percentage =
            item.filledQuantity / item.quantity * 100;
        //((item.quantity - item.filledQuantity) * 100) / item.quantity;
        let char = percentage + " 100";

        return (
            <tr key={index}>
                <td>{strSplit[0] + "," + strSplit[1] + " | " + time}</td>
                <td>
                    <span className={item.side}>{item.side}</span>
                </td>
                <td>{item.price}</td>
                <td>{item.quantity}</td>
                <td>{item.filledQuantity}</td>
                <td>{item.filledPrice}</td>
                <td>{item.cancelledQuantity}</td>
                <td>
                    <svg viewBox="0 0 32 32">
                        <circle
                            cx="50%"
                            cy="50%"
                            r="16"
                            stroke={bgc}
                            strokeDasharray={char}
                            strokeDashoffset="0"
                        ></circle>
                    </svg>
                </td>
            </tr>
        );
    });
    return (
        <>{ordersHistoryItem}</>
    );
}

export default OrderHistoryAsset;