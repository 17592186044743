import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {Scrollbars} from "react-custom-scrollbars-2";
import ActiveOrderAsset from "./ActiveOrderAsset";
import OrderHistoryAsset from "./OrderHistoryAsset";
import Pagination from '../../Pagination';
import DateFromFilter from '../../DateFromFilter';
import { dataFilterChanged } from '../../store/slices/TradingSlice';
import EmptyIcon from "../EmptyIcon/EmptyIcon";

const OrderTabs = ({
    activeOrders,
    ordersHistory,
    onActiveOrdersClick,
    onOrdersHistoryClick,
    onOrderCancel,
 }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('activeOrders');
    const {
        data: ordersHistoryData,
        dateFrom,
        currentPage,
        totalPages,
        itemsPerPage,
        count,
    } = ordersHistory;

    return (
        <div className="order_traders_tabs_wrap">
            <Tabs
                onSelect={(index) => {
                    let tab = 'activeOrders';
                    switch (index) {
                      case 0:
                        tab = 'activeOrders';
                        onActiveOrdersClick();
                        break;
                      case 1:
                        tab = 'ordersHistory';
                        onOrdersHistoryClick();
                        break;
                      default:
                        // never happens
                    }
                    setSelectedTab(tab);
                }}
            >
                <TabList>
                    <Tab>
                        <svg
                            width="14"
                            height="17"
                            viewBox="0 0 14 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1184_12280)">
                                <g clipPath="url(#clip1_1184_12280)">
                                    <path d="M10.5 10.6504C8.92951 10.6504 7.65625 11.9237 7.65625 13.4941C7.65625 15.0646 8.92951 16.3379 10.5 16.3379C12.0705 16.3379 13.3438 15.0646 13.3438 13.4941C13.3438 11.9237 12.0705 10.6504 10.5 10.6504ZM11.8125 13.9316H10.5C10.442 13.9316 10.3863 13.9086 10.3453 13.8676C10.3043 13.8265 10.2812 13.7709 10.2812 13.7129V11.7441C10.2812 11.6861 10.3043 11.6305 10.3453 11.5895C10.3863 11.5484 10.442 11.5254 10.5 11.5254C10.558 11.5254 10.6137 11.5484 10.6547 11.5895C10.6957 11.6305 10.7188 11.6861 10.7188 11.7441V13.4941H11.8125C11.8705 13.4941 11.9262 13.5172 11.9672 13.5582C12.0082 13.5992 12.0312 13.6549 12.0312 13.7129C12.0312 13.7709 12.0082 13.8265 11.9672 13.8676C11.9262 13.9086 11.8705 13.9316 11.8125 13.9316Z" />
                                </g>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 2.99414C0 1.88957 0.895431 0.994141 2 0.994141H9C10.1046 0.994141 11 1.88957 11 2.99414V10.0296C10.8367 10.0062 10.6698 9.99414 10.5 9.99414C8.567 9.99414 7 11.5611 7 13.4941C7 14.0309 7.12085 14.5395 7.33682 14.9941H2C0.895431 14.9941 0 14.0987 0 12.9941V2.99414ZM8.24807 3.56002C8.48783 3.69702 8.57113 4.00245 8.43412 4.24221L6.64841 7.36721L6.37651 7.84303L5.92755 7.52876L4.63369 6.62305L3.45474 9.20202C3.33993 9.45317 3.04326 9.56369 2.79212 9.44888C2.54098 9.33407 2.43045 9.03741 2.54526 8.78626L3.97383 5.66126L4.22345 5.11523L4.7153 5.45952L6.05206 6.39525L7.56588 3.74607C7.70288 3.50631 8.00831 3.42301 8.24807 3.56002Z"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1184_12280">
                                    <rect
                                        width="14"
                                        height="16"
                                        fill="white"
                                        transform="translate(0 0.994141)"
                                    />
                                </clipPath>
                                <clipPath id="clip1_1184_12280">
                                    <rect
                                        width="7"
                                        height="7"
                                        fill="white"
                                        transform="translate(7 9.99414)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>Active Orders</span>
                    </Tab>
                    <Tab>
                        <svg
                            width="14"
                            height="17"
                            viewBox="0 0 14 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1184_12268)">
                                <g clipPath="url(#clip1_1184_12268)">
                                    <path d="M10.5 10.6504C8.92951 10.6504 7.65625 11.9237 7.65625 13.4941C7.65625 15.0646 8.92951 16.3379 10.5 16.3379C12.0705 16.3379 13.3438 15.0646 13.3438 13.4941C13.3438 11.9237 12.0705 10.6504 10.5 10.6504ZM11.8125 13.9316H10.5C10.442 13.9316 10.3863 13.9086 10.3453 13.8676C10.3043 13.8265 10.2812 13.7709 10.2812 13.7129V11.7441C10.2812 11.6861 10.3043 11.6305 10.3453 11.5895C10.3863 11.5484 10.442 11.5254 10.5 11.5254C10.558 11.5254 10.6137 11.5484 10.6547 11.5895C10.6957 11.6305 10.7188 11.6861 10.7188 11.7441V13.4941H11.8125C11.8705 13.4941 11.9262 13.5172 11.9672 13.5582C12.0082 13.5992 12.0312 13.6549 12.0312 13.7129C12.0312 13.7709 12.0082 13.8265 11.9672 13.8676C11.9262 13.9086 11.8705 13.9316 11.8125 13.9316Z" />
                                </g>
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 2.99414C0 1.88957 0.895431 0.994141 2 0.994141H9C10.1046 0.994141 11 1.88957 11 2.99414V10.0296C10.8367 10.0062 10.6698 9.99414 10.5 9.99414C8.567 9.99414 7 11.5611 7 13.4941C7 14.0309 7.12085 14.5395 7.33682 14.9941H2C0.895431 14.9941 0 14.0987 0 12.9941V2.99414ZM2.5 3.99414C2.5 3.718 2.72386 3.49414 3 3.49414H8C8.27614 3.49414 8.5 3.718 8.5 3.99414C8.5 4.27028 8.27614 4.49414 8 4.49414H3C2.72386 4.49414 2.5 4.27028 2.5 3.99414ZM2.5 6.49414C2.5 6.218 2.72386 5.99414 3 5.99414H8C8.27614 5.99414 8.5 6.218 8.5 6.49414C8.5 6.77028 8.27614 6.99414 8 6.99414H3C2.72386 6.99414 2.5 6.77028 2.5 6.49414ZM2.5 8.99414C2.5 8.718 2.72386 8.49414 3 8.49414H5.14286C5.419 8.49414 5.64286 8.718 5.64286 8.99414C5.64286 9.27028 5.419 9.49414 5.14286 9.49414H3C2.72386 9.49414 2.5 9.27028 2.5 8.99414Z"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1184_12268">
                                    <rect
                                        width="14"
                                        height="16"
                                        fill="white"
                                        transform="translate(0 0.994141)"
                                    />
                                </clipPath>
                                <clipPath id="clip1_1184_12268">
                                    <rect
                                        width="7"
                                        height="7"
                                        fill="white"
                                        transform="translate(7 9.99414)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>Orders History</span>
                    </Tab>
                    {'ordersHistory' === selectedTab && (
                        <span className="orderTradesHistory">
                            {/* date from filter */}
                            <DateFromFilter
                                value={dateFrom}
                                onChange={( textValue ) => {
                                    dispatch(dataFilterChanged({
                                        dataType: 'ordersHistory',
                                        dateFrom: (textValue !== 'custom') ? textValue : null,
                                        offset: 0,
                                    }));
                                }}
                            />
                        </span>
                    )}
                </TabList>
                        <TabPanel>
                            <div className="tab_wrapp">
                                <div className="content_table">
                                    { activeOrders.length === 0 ?
                                        <EmptyIcon></EmptyIcon>:
                                        <Scrollbars style={{minWidth: 1080, height: 450}}
                                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                        >
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Side</th>
                                                    <th>Price</th>
                                                    <th>Active Amt</th>
                                                    <th>Filled Amt</th>
                                                    <th>Filled Price</th>
                                                    <th>TIF</th>

                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ActiveOrderAsset
                                                    orders={activeOrders}
                                                    onCancelClick={(orderParams) => {
                                                        onOrderCancel(orderParams);
                                                    }}
                                                ></ActiveOrderAsset>
                                                </tbody>
                                            </table>
                                        </Scrollbars>
                                    }
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="tab_wrapp">
                                <div className="content_table">
                                    { ordersHistoryData.length === 0 ?
                                        <EmptyIcon></EmptyIcon> :
                                        <Scrollbars style={{minWidth: 1080, height: 450}}>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Side</th>
                                                <th>Price</th>
                                                <th>Original Amt</th>
                                                <th>Filled Amt</th>
                                                <th>Filled Price</th>
                                                <th>Canceled Amt</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <OrderHistoryAsset ordersHistory={ordersHistoryData}></OrderHistoryAsset>
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                    }
                                </div>
                            </div>
                            <div style={{display: 'none'}}>
                                {/* pagination  */}
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    count={count}
                                    onFirstClick={() => {
                                        dispatch(dataFilterChanged({
                                            dataType: 'ordersHistory',
                                            offset: 0,
                                        }));
                                    }}
                                    onPrevClick={() => {
                                        dispatch(dataFilterChanged({
                                            dataType: 'ordersHistory',
                                            offset: (currentPage - 2) * itemsPerPage,
                                        }));
                                    }}
                                    onNextClick={() => {
                                        dispatch(dataFilterChanged({
                                            dataType: 'ordersHistory',
                                            offset: currentPage * itemsPerPage,
                                        }));
                                    }}
                                    onLastClick={() => {
                                        dispatch(dataFilterChanged({
                                            dataType: 'ordersHistory',
                                            offset: (totalPages - 1) * itemsPerPage,
                                        }));
                                    }}
                                    onRowsChange={(rows) => {
                                        dispatch(dataFilterChanged({
                                            dataType: 'ordersHistory',
                                            offset: 0,
                                            limit: parseInt(rows),
                                        }));
                                    }}
                                />
                            </div>
                        </TabPanel>
            </Tabs>
        </div>
    );
}

export default OrderTabs;