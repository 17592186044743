import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import Footer from "../Footer";
import Header from "../Header";
import Asset from '../Asset';
import BalanceStrip from "../Balance";
import LiveTrades from "./LiveTrades/LiveTrades";
import DropDownCustom from "./PlaceOrder/DropDown";
import OrderBookBidAsk from "./OrderBookBidAsk/OrderBookBidAsk";
import OrderTabs from "./OrderTabs/OrderTabs"
import PlaceOrder from "./PlaceOrder/PlaceOrder";
import {useAuth0} from "@auth0/auth0-react";
import { Scrollbars } from "react-custom-scrollbars-2";
import {Link, useParams} from "react-router-dom";
import ChartTrades from "./Chart/Chart";
import { setCurrentInstrumentSymbol } from '../store/slices/InstrumentsSlice';
import { orderPlaced } from '../store/slices/TradingSlice';
import { modalShown, modalClosed, MODAL_CANCEL_ORDER_TYPE, MODAL_PLACE_ORDER_TYPE } from '../store/slices/UiSlice';
import {
    selectBalance,
    makeSelectInstrumentsOrderBy,
    selectCurrentInstrument,
    selectOrderBook,
    makeSelectLiveTradesWithSort,
    makeSelectInstrumentActiveOrdersWithSort,
    selectInstrumentOrdersHistory,
    selectModal,
} from '../store/selectors';
import {
    dataSubscriptionsChanged,
    INSTRUMENTS_SUBSCRIPTION,
    TRADING_SESSION_SUBSCRIPTION,
    MARKET_DATA_SESSION_SUBSCRIPTION,
    LIGHT_TICKERS_SUBSCRIPTION,
    BALANCE_SUBSCRIPTION,
    INSTRUMENT_ACTIVE_ORDERS_SUBSCRIPTION,
    INSTRUMENT_LIVE_TRADES_SUBSCRIPTION,
    INSTRUMENT_ORDER_BOOK_SUBSCRIPTION,
    INSTRUMENT_ORDERS_HISTORY,
} from '../store/slices/SocketsSlice';
import EmptyIcon from "./EmptyIcon/EmptyIcon";
import {Tabs, TabList, TabPanel} from "react-tabs";

const preloadedData = [
    INSTRUMENTS_SUBSCRIPTION,
    TRADING_SESSION_SUBSCRIPTION,
    MARKET_DATA_SESSION_SUBSCRIPTION,
    LIGHT_TICKERS_SUBSCRIPTION,
    BALANCE_SUBSCRIPTION,
    INSTRUMENT_LIVE_TRADES_SUBSCRIPTION,
    INSTRUMENT_ORDER_BOOK_SUBSCRIPTION,
];

const TrandingScreen = ({ children }) => {
    const { id } = useParams();
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const balanceData = useSelector(selectBalance);
    const selectedInstrument = useSelector(selectCurrentInstrument);
    // create dynamic selector
    // Create a new memoized selector, for each component instance, on mount
    const selectInstruments = useMemo(() => makeSelectInstrumentsOrderBy({ field: 'symbol', dir: 'asc' }), []);
    const selectLiveTrades = useMemo(() => makeSelectLiveTradesWithSort({ limit: 50, sortIndex: 3, sortDir: 'desc' }), []);
    const selectChartData = useMemo(() => makeSelectLiveTradesWithSort({ sortIndex: 3, sortDir: 'asc' }), []);
    const selectInstrumentActiveOrders = useMemo(() => makeSelectInstrumentActiveOrdersWithSort({ sortIndex: 'createdAt', sortDir: 'desc' }), []);
    const instrumentsData = useSelector(selectInstruments);
    const liveTradesData = useSelector(selectLiveTrades);
    const chartData = useSelector(selectChartData);
    const orderBooksData = useSelector(selectOrderBook);
    const activeOrdersData = useSelector(selectInstrumentActiveOrders);
    const ordersHistory = useSelector(selectInstrumentOrdersHistory);
    const modal = useSelector(selectModal);
    const isPlaceOrderModalShown = MODAL_PLACE_ORDER_TYPE === modal?.type;
    // click handlers
    const onBuyBtnClick = () => dispatch(modalShown({ type: MODAL_PLACE_ORDER_TYPE }));
    const onModalCloseClick = () => dispatch(modalClosed());

    // place order form
    const hookForm = useForm({
        defaultValues: {
            orderType: 'Limit',
            side: 'Buy',
            instrument: selectedInstrument?.symbol,
            quantity: "",
            price: "",
            // Limit order: GTC (default)|GTD|IOC|FOK
            // Market order: IOC (default)|FOK
            timeInForce: 'GTC',
            timeInForceMarketOnly: 'IOC',
            expiryDate: null,
        }
    });

    useEffect(() => {
        hookForm.setValue('instrument', id);
        dispatch(setCurrentInstrumentSymbol({ symbol: id }));
        dispatch(dataSubscriptionsChanged([
            ...preloadedData,
            INSTRUMENT_ACTIVE_ORDERS_SUBSCRIPTION,
        ]));

        return () => {
            // reset instrument id on unmount
            dispatch(setCurrentInstrumentSymbol({ symbol: null }));
        }
    }, [id]);

    return(
        <div className="page_wrapp_tranding">
            <Header username={user.name}>
            </Header>
            <Scrollbars style={{ height: 107 }} className="wrapBalanceTrade">
                <BalanceStrip balance={balanceData}/>
            </Scrollbars>
            <div className="main_traiders_screen">
                {/* nested popup */}
                {children}
                {/* nested popup */}
                <div className="column_left">
                    <div className="first_row_nav">
                        <Link to="/">
                            <span>Back</span>
                        </Link>
                        <DropDownCustom>
                            <Asset instruments={instrumentsData} />
                        </DropDownCustom>
                        <p>{selectedInstrument?.symbol}</p>
                    </div>
                    <Scrollbars className="wrapScroll"
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                                style={{ height: '62vh'}}>
                        <div className="nav_inst_details">
                            <div className="second_row_naw">
                                <div className="left_column_naw">
                                    <img src={selectedInstrument?.imageUrls[0] ?? null} alt="asset-img"/>
                                </div>
                                <div className="right_column_naw">
                                    <p>{selectedInstrument?.description}</p>
                                    <div className="bs_label_content">
                                        <span className="bs_value">{selectedInstrument?.bidPrice}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="live_trades">
                            { liveTradesData.length === 0 ?
                                <Tabs>
                                    <TabList>
                                        <div className="title_row">
                                            <svg
                                                width="11"
                                                height="15"
                                                viewBox="0 0 11 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M2 0.994141C0.895431 0.994141 0 1.88957 0 2.99414V12.9941C0 14.0987 0.895431 14.9941 2 14.9941H9C10.1046 14.9941 11 14.0987 11 12.9941V2.99414C11 1.88957 10.1046 0.994141 9 0.994141H2ZM3 3.49414C2.72386 3.49414 2.5 3.718 2.5 3.99414C2.5 4.27028 2.72386 4.49414 3 4.49414H8C8.27614 4.49414 8.5 4.27028 8.5 3.99414C8.5 3.718 8.27614 3.49414 8 3.49414H3ZM3 5.99414C2.72386 5.99414 2.5 6.218 2.5 6.49414C2.5 6.77028 2.72386 6.99414 3 6.99414H8C8.27614 6.99414 8.5 6.77028 8.5 6.49414C8.5 6.218 8.27614 5.99414 8 5.99414H3ZM3 8.49414C2.72386 8.49414 2.5 8.718 2.5 8.99414C2.5 9.27028 2.72386 9.49414 3 9.49414H5.14286C5.419 9.49414 5.64286 9.27028 5.64286 8.99414C5.64286 8.718 5.419 8.49414 5.14286 8.49414H3Z"
                                                />
                                            </svg>
                                            <span>Trades</span>
                                        </div>
                                    </TabList>
                                    <TabPanel>
                                        <EmptyIcon></EmptyIcon>
                                    </TabPanel>
                                </Tabs> :
                                <>
                                <div className="left_column_trad">
                                    <div className="title_row">
                                        <svg
                                            width="11"
                                            height="15"
                                            viewBox="0 0 11 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M2 0.994141C0.895431 0.994141 0 1.88957 0 2.99414V12.9941C0 14.0987 0.895431 14.9941 2 14.9941H9C10.1046 14.9941 11 14.0987 11 12.9941V2.99414C11 1.88957 10.1046 0.994141 9 0.994141H2ZM3 3.49414C2.72386 3.49414 2.5 3.718 2.5 3.99414C2.5 4.27028 2.72386 4.49414 3 4.49414H8C8.27614 4.49414 8.5 4.27028 8.5 3.99414C8.5 3.718 8.27614 3.49414 8 3.49414H3ZM3 5.99414C2.72386 5.99414 2.5 6.218 2.5 6.49414C2.5 6.77028 2.72386 6.99414 3 6.99414H8C8.27614 6.99414 8.5 6.77028 8.5 6.49414C8.5 6.218 8.27614 5.99414 8 5.99414H3ZM3 8.49414C2.72386 8.49414 2.5 8.718 2.5 8.99414C2.5 9.27028 2.72386 9.49414 3 9.49414H5.14286C5.419 9.49414 5.64286 9.27028 5.64286 8.99414C5.64286 8.718 5.419 8.49414 5.14286 8.49414H3Z"
                                            />
                                        </svg>
                                        <span>Trades</span>
                                    </div>
                                    <Scrollbars style={{ height: 415 }}>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Amount</th>
                                                <th>Price</th>
                                                <th>Date & Time</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <LiveTrades liveTrades={liveTradesData} />
                                            </tbody>
                                        </table>
                                    </Scrollbars>
                                </div>
                                <div className="right_column_trad">
                                <ChartTrades liveTrades={chartData} />
                                </div>
                                </>
                            }
                        </div>
                        <div className="order_book">
                            <div className="title_row">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.32 6.31259C9.19094 5.77353 7.61281 5.50947 5.5 5.50009C5.30074 5.49738 5.10539 5.55549 4.94 5.66665C4.80425 5.75841 4.69312 5.88212 4.6164 6.02691C4.53967 6.17169 4.4997 6.33311 4.5 6.49697V15.4376C4.5 16.042 4.93 16.4979 5.5 16.4979C7.72094 16.4979 9.94875 16.7054 11.2831 17.9667C11.3014 17.984 11.3243 17.9956 11.3491 18C11.3739 18.0044 11.3994 18.0014 11.4225 17.9914C11.4456 17.9814 11.4653 17.9649 11.4791 17.9438C11.4928 17.9227 11.5001 17.8981 11.5 17.8729V7.33822C11.5001 7.26714 11.4849 7.19688 11.4554 7.13218C11.426 7.06749 11.383 7.00987 11.3294 6.96322C11.0237 6.70191 10.6842 6.48306 10.32 6.31259Z" fill="#F6F6F6"/>
                                    <path d="M19.06 5.66525C18.8945 5.55436 18.6992 5.49658 18.5 5.49963C16.3872 5.509 14.8091 5.77181 13.68 6.31213C13.3158 6.48229 12.9762 6.70071 12.6703 6.9615C12.6168 7.00824 12.5739 7.06588 12.5446 7.13056C12.5152 7.19524 12.5 7.26546 12.5 7.3365V17.8718C12.5 17.896 12.5071 17.9197 12.5205 17.9398C12.5339 17.96 12.5529 17.9757 12.5752 17.9851C12.5975 17.9945 12.6221 17.9971 12.6458 17.9925C12.6696 17.988 12.6915 17.9766 12.7087 17.9596C13.5109 17.1628 14.9187 16.4965 18.5012 16.4968C18.7665 16.4968 19.0208 16.3915 19.2083 16.2039C19.3959 16.0164 19.5012 15.762 19.5012 15.4968V6.49681C19.5016 6.33263 19.4615 6.17089 19.3846 6.02586C19.3076 5.88084 19.1962 5.75699 19.06 5.66525Z" fill="#F6F6F6"/>
                                </svg>
                                <span>Order book</span>
                            </div>
                            { orderBooksData.length !== 0 ?
                                <Scrollbars style={{ height: 465 }}>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Amount</th>
                                            <th>Bid</th>
                                            <th>Ask</th>
                                            <th>Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <OrderBookBidAsk orderBooks={orderBooksData} />
                                        </tbody>
                                    </table>
                                </Scrollbars> :
                                <Tabs>
                                    <TabList></TabList>
                                    <TabPanel>
                                        <EmptyIcon></EmptyIcon>
                                    </TabPanel>
                                </Tabs>
                            }
                        </div>
                        <div className="order_tabs">
                            <OrderTabs
                                activeOrders={activeOrdersData}
                                ordersHistory={ordersHistory}
                                onActiveOrdersClick={() => {
                                    dispatch(dataSubscriptionsChanged([
                                        ...preloadedData,
                                        INSTRUMENT_ACTIVE_ORDERS_SUBSCRIPTION,
                                    ]));
                                }}
                                onOrdersHistoryClick={() => {
                                    dispatch(dataSubscriptionsChanged([
                                        ...preloadedData,
                                        INSTRUMENT_ORDERS_HISTORY,
                                    ]));
                                }}
                                onOrderCancel={({ orderId, instrument }) => {
                                    dispatch(modalShown({
                                        type: MODAL_CANCEL_ORDER_TYPE,
                                        orderId,
                                        instrument,
                                    }));
                                }}
                            />
                        </div>
                    </Scrollbars>
                </div>
                <div className={`place_order ${isPlaceOrderModalShown ? 'show' : ''}`}>
                    <div className="mob-btn-close">
                        <button
                            type="button"
                            onClick={onModalCloseClick}
                        >
                            &times;
                        </button>
                    </div>
                    <h3>Place order</h3>
                        <PlaceOrder
                            hookForm={hookForm}
                            onSubmit={({ expiryDate, ...values }) => {
                                if (expiryDate instanceof Date) {
                                    values.expiryDate = expiryDate.valueOf() / 1000;
                                }
                                dispatch(orderPlaced(values));
                            }}
                        >
                        </PlaceOrder>
                </div>
            </div>
            <div className="mob_button">
                <button
                    className="mob_buy"
                    type="button"
                    onClick={onBuyBtnClick}
                >
                    Buy
                </button>
                <button
                    className="mob_sell"
                    type="button"
                    onClick={onBuyBtnClick}
                >
                    Sell
                </button>
            </div>
            <Footer/>
        </div>
    );
}

export default TrandingScreen;