import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  TRADING_WS: {
    readyState: 3,// means closed
    sessionCreated: false,
    error: null, // { data: { code, message } }
    isError: false,
  },
  MARKET_DATA_WS: {
    readyState: 3,// means closed
    sessionCreated: false,
    error: null, // { data: { code, message } }
    isError: false,
  },
  /** @var string[] unique array of subscriptions to data: instruments, balances, etc */
  dataSubscriptions: [],
  // handler streams one by one
  // order matters
  // cannot make close before open etc.
  // cannot make request before session create
  streamsQueue: [

  ],
  // used streams
  closedSids: [],
  notifications: [],
};

const getNewSid = (streams, closed) => {
  return streams.map(({ sid = 0 }) => sid)
    .concat(closed)
    .reduce(
      (prev, current) => Math.max(prev, current),
      0
    ) + 1;
};

const slice = createSlice({
  name: 'sockets',
  initialState,
  reducers: {
    dataSubscriptionsChanged(state, { payload }) {
      // don't push duplicates
      const clone = payload.concat();
      payload.forEach(subType => {
        if (!clone.includes(subType)) {
          clone.push(subType);
        }
      });
      state.dataSubscriptions = clone;
    },
    socketStateChanged(state, { payload }) {
      const { socketName, ...rest } = payload;
      Object.assign(state[payload.socketName], rest);
    },
    streamsStateChanged(state, { payload }) {
      const {
        created = [], // full stream body
        closed = [], // sids array
        opened = [], // sids array
        failed = [], // sid + reason
      } = payload;

      // wipe of killed streams
      state.streamsQueue = state.streamsQueue.filter(item => !closed?.includes(item?.sid));
      state.closedSids = state.closedSids.concat(closed);

      // mark opened and remove previous error
      state.streamsQueue.forEach(item => {
        if (opened?.includes(item?.sid)) {
          item.sig = 1;
          delete item?.failedReason;
        }
      });

      // mark failed
      failed.forEach(({ sid: failedSid, reason }) => {
        const item = state.streamsQueue.find(({ sid }) => sid === failedSid);
        Object.assign(item, { failedReason: reason });
      });

      // push initiated streams
      state.streamsQueue = state.streamsQueue.concat(created);
    },
    streamDataPushed(state, { payload }) {
      const { sig, sid } = payload;
      const stream = state.streamsQueue.find((item) => sid === item.sid);
      switch (sig) {
        case 1:
          if ([TRADING_SESSION_SUBSCRIPTION, MARKET_DATA_SESSION_SUBSCRIPTION].includes(stream?.subType)) {
            // session created successfully
            state[stream.socketName].sessionCreated = true;
          }
          break;
        case 2:
          // error
          stream.sig = sig;
          state[stream.socketName].isError = true;
          state[stream.socketName].error = {
            data: {
              type: payload?.errorType || 500,
              code: payload?.d.errorCode,
              message: payload?.d.errorMessage || `Undefined error at ${stream.socketName} socket`,
            },
          };
          state.notifications.push(payload?.d);
          break;
        default:
          // ignore
      }

      if (['Rejected', 'Added', 'Executed', 'Cancelled'].includes(payload?.d?.messageType)) {
        state.notifications.push(payload?.d);
      }
    },
  },
});

export const {
  dataSubscriptionsChanged,
  socketStateChanged,
  streamsStateChanged,
  streamDataPushed,
} = slice.actions;

export default slice.reducer;

// data subscription types
// main screen
export const INSTRUMENTS_SUBSCRIPTION = 'instruments_subscription';
export const BALANCE_SUBSCRIPTION = 'balance_subscription';
export const LIGHT_TICKERS_SUBSCRIPTION = 'light_tickers_subscription';
// trading screen
export const INSTRUMENT_LIVE_TRADES_SUBSCRIPTION = 'instrument_live_trades_subscription';
export const INSTRUMENT_ORDER_BOOK_SUBSCRIPTION = 'instrument_order_book_subscription';
export const INSTRUMENT_ACTIVE_ORDERS_SUBSCRIPTION = 'instrument_active_orders_subscription';
export const INSTRUMENT_ORDERS_HISTORY = 'instrument_orders_history';
// orders & trades screen
export const ACTIVE_ORDERS_SUBSCRIPTION = 'active_orders_subscription';
export const ORDERS_HISTORY_SUBSCRIPTION = 'orders_history_subscription';
export const TRADES_HISTORY_SUBSCRIPTION = 'trades_history_subscription';
// system subscriptions
// we might remove them later
export const TRADING_SESSION_SUBSCRIPTION = 'trading_session_subscription';
export const MARKET_DATA_SESSION_SUBSCRIPTION = 'market_data_session_subscription';
// user initiated streams
export const PLACE_ORDER_SUBSCRIPTION = 'place_order_subscription';
export const CANCEL_ORDER_SUBSCRIPTION = 'cancel_order_subscription';

export const selectLatestNotification = createSelector(
  (state) => state.sockets.notifications,
  (notifications) => notifications.length ? notifications.concat().pop() : null,
);

export const selectNewSid = createSelector(
  (state) => state.sockets.streamsQueue,
  (state) => state.sockets.closedSids,
  getNewSid,
);
