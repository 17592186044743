import React from "react";
import styled from "styled-components";
import { DateTime } from "luxon";

import EmptyIcon from "../../TrandingScreen/EmptyIcon/EmptyIcon";
import {Scrollbars} from "react-custom-scrollbars-2";
import { getFormattedDate } from "../../TrandingScreen/OrderTabs/ActiveOrderAsset";

const DeleteItem = styled('button')`
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    color: #0D0D0D;
    border: none;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background: ${props => props.bgah}
    }
}
`

const OredersActiveOrders = ({
    activeOrders,
    onCancelClick,
}) => {
    let ordersItems;
    if(activeOrders.length !== 0) {
         ordersItems = activeOrders.map((item, index) => {
             const { orderId, instrument, createdAt, expiryDate = null } = item;

             return (
                 <tr key={index}>
                     <td>{getFormattedDate(DateTime.fromMillis(createdAt))}</td>
                     <td>
                         <span className="instr">{item.instrument}</span>
                     </td>
                     <td>
                         <span className={item.side}>{item.side}</span>
                     </td>
                     <td>{item.price}</td>
                     <td>{item.quantity}</td>
                     <td>{item.filledQuantity}</td>
                     <td>{item.filledPrice}</td>
                     <td>
                        {item.timeInForce}
                        {expiryDate && ` ${getFormattedDate(DateTime.fromSeconds(expiryDate))}`}
                    </td>

                     <td>
                         <DeleteItem
                            bgah={process.env.REACT_APP_BRAND_CTA_BG_Color}
                            onClick={() => onCancelClick({ orderId, instrument })}
                        >
                            &ndash;
                        </DeleteItem>
                     </td>
                 </tr>
             );
         });
    }

    return(
      <div className="tab_wrapp">
          <div className="content_table">
              <Scrollbars style={{ height: 'calc(70vh - 50px)'}}
                 renderTrackVertical={({ style, ...props }) =>
                     <div {...props} style={{ ...style, width: 4,
                         right: 0,
                         bottom: 2,
                         top: 2,
                         borderRadius: 3 }}/>
                 }
                 renderView={props => <div {...props} className="view"/>}
              >
                <table className="table">
                  <thead>
                  <tr>
                      <th>Date</th>
                      <th>Instrument</th>
                      <th>Side</th>
                      <th>Price</th>
                      <th>Active Amt</th>
                      <th>Filled Amt</th>
                      <th>Filled Price</th>
                      <th>TIF</th>

                      <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {ordersItems !== undefined ? ordersItems : <EmptyIcon></EmptyIcon>}
                    </tbody>
                </table>
              </Scrollbars>
            </div>
        </div>
    )
}

export default OredersActiveOrders;