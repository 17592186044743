import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { createChart } from 'lightweight-charts';
import { debounce } from 'lodash';

const timezoneOffsetSeconds = new Date().getTimezoneOffset() * 60;

/**
 * Data chart looks really ugly without this timestamp adjustment.
 * @param {object[]} data Live trades data
 * @returns {object[]}
 */
const adjustDataTimestamps = (data) => {
    let lastTimestamp; // global timestamp

    return data.map((item) => {
        const time = (lastTimestamp >= item[3]) ? lastTimestamp + 1 : item[3];
        lastTimestamp = time;
        return { time: time / 1000 - timezoneOffsetSeconds, value: item[0] };
    });
};

const ChartTrades = ({ liveTrades }) => {
    const divRef = useRef(null);
    const [{ chart, series }, setChart] = useState({});
    const adjustedData = useMemo(() => adjustDataTimestamps(liveTrades), [liveTrades]);
    const resizeCallback = useCallback(() => {
        if (chart && divRef.current) {
            chart.resize(divRef.current?.clientWidth, divRef.current?.clientHeight);
        }
    }, [chart]);

    // add window resize listener
    useEffect(() => {
        const onResize = debounce(resizeCallback, 500);
        window.removeEventListener('resize', onResize);
        window.addEventListener('resize', onResize);

        return () => {
            // destroy chart on unmount
            window.removeEventListener('resize', onResize);
            if (chart?.remove) {
                chart.remove();
                setChart({ chart: null, series: null });
            }
        };
    }, [resizeCallback]);

    // draw chart when data is ready
    useEffect(() => {
        if (divRef.current && adjustedData.length) {
            if (!series) {
                const chartOptions = {
                    layout: {
                        background: {
                            type: 'solid',
                            color: '#0D0D0D',
                        },
                        textColor: 'white',
                    },
                    grid: {
                        vertLines: {
                            color: 'rgba(42, 46, 57, 0)'
                        },
                        horzLines: {
                            color: 'rgba(65, 66, 68, 1)'
                        }
                    },
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: true,
                    },
                    leftPriceScale: {
                         visible: true,
                   },
                    rightPriceScale: {
                        visible: false,
                   }
                };
                const newChart = createChart(divRef.current, chartOptions);
                const newSeries = newChart.addAreaSeries({
                    lineColor: 'rgb(59, 211, 136)',
                    topColor: 'rgb(59, 211, 136, 0.9)',
                    bottomColor: 'rgba(59, 211, 136, 0.5)',
                });
                newSeries.setData(adjustedData);
                newChart.timeScale().fitContent();
                setChart({ chart: newChart, series: newSeries });
            } else {
                series.setData(adjustedData);
                chart.timeScale().fitContent();
            }
        }
    }, [adjustedData]);

    return (
        <div>
            <div
                ref={divRef}
                style={{ width: '100%', height: 'calc(60vh - 32px - 20px)' }}
            />
        </div>
    );
};

export default ChartTrades;
