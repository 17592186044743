import React, { useState } from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars-2";

const DropDownContainer = styled("div")`
    width: 90px;
    margin-top: -8px;
    margin-right: 10px;
    @media (max-width: 768px) {
        order: 3;
    }
`;

const OverHover = styled("div")`
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    opacity: 0.7;
    z-index: 3;
    position: absolute;
    background: #000;
    @media(max-width: 768px){
        display: ${props => props.HoverTriger ? 'block ': 'none'};
    }
`

const DropDownHeader = styled("div")`
    width: 100%;
    border: 2px solid #707070;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    padding: 8px 2px 8px 12px;
    margin-right: 10px;
    cursor: pointer;
        &:hover{
        background: #0D0D0D;
    }
    &:after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L6.8 6.8L11.6 2' stroke='%23C4C4C4' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E");
        width: 14px;
        height: 8px;
        margin: 0 8px;
        display: inline-block;
        background-repeat: no-repeat;
    }
`;

const DropDownListContainer = styled("div")`
    width: 433px;
    z-index: 1;
    position: absolute;
    @media (max-width: 768px) {
        left: 0;
        bottom: 0;
        width: 100vw;
        z-index: 3;
    }
  }
`;

const WrapTitle = styled("div")`
    height: 30px;
    display: flex;
    position: relative;
    align-items: baseline;
    @media(max-width: 768px){
        justify-content: center;
    }
`;

const BtnClose = styled("span")`
    display: none;
    background: #fff;
    width: 35px;
    height: 5px;
    border-radius: 5px;
    @media(max-width: 768px){
        display: inline-block;
    }
`;

const Title = styled("h1")`
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 17px;
    letter-spacing: 0em;
    color: #F6F6F6;
    &:before{
        content: "";
        width: 14px;
        height: 14px;
        padding-left: 10px;
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3112 14.0005C12.2059 14.0009 12.1033 13.9681 12.0177 13.9067L7.99869 10.993L3.97962 13.9067C3.89376 13.969 3.79031 14.0024 3.68424 14.002C3.57817 14.0016 3.47497 13.9675 3.38956 13.9046C3.30415 13.8417 3.24096 13.7532 3.20912 13.6521C3.17728 13.5509 3.17844 13.4422 3.21244 13.3417L4.77994 8.69891L0.717436 5.91298C0.62944 5.8527 0.563025 5.76586 0.527896 5.66515C0.492767 5.56444 0.490764 5.45513 0.522181 5.3532C0.553598 5.25127 0.616788 5.16206 0.702518 5.0986C0.788247 5.03514 0.892024 5.00076 0.998686 5.00048H6.01056L7.52306 0.34579C7.55564 0.245296 7.61922 0.157706 7.70467 0.0955837C7.79011 0.0334614 7.89304 0 7.99869 0C8.10433 0 8.20726 0.0334614 8.29271 0.0955837C8.37815 0.157706 8.44173 0.245296 8.47431 0.34579L9.98681 5.00204H14.9987C15.1055 5.00199 15.2095 5.03613 15.2955 5.09947C15.3815 5.16281 15.4449 5.25201 15.4765 5.35402C15.5081 5.45603 15.5063 5.56548 15.4712 5.66634C15.4361 5.76721 15.3696 5.85419 15.2815 5.91454L11.2174 8.69891L12.784 13.3405C12.8094 13.4156 12.8165 13.4957 12.8048 13.5742C12.7931 13.6526 12.7629 13.7272 12.7168 13.7917C12.6706 13.8561 12.6097 13.9087 12.5392 13.945C12.4686 13.9813 12.3905 14.0003 12.3112 14.0005Z' fill='%23F6F6F6'/%3E%3C/svg%3E");
    }
    @media(max-width: 768px){
        left: 0px;
        position: absolute;
    }
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  padding-right: 1em;
  background: #0D0D0D;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 4px;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;

const DropDownCustom = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);

    function dropuns(){
        setIsOpen(!isOpen);
    }

    return (
        <DropDownContainer>
            <OverHover HoverTriger={isOpen}></OverHover>
                <DropDownHeader onClick={toggling}>Assets</DropDownHeader>
            {isOpen && (
                <DropDownListContainer>
                    <DropDownList>
                        <WrapTitle>
                            <Title>Asset list</Title>
                            <BtnClose onClick={toggling}></BtnClose>
                        </WrapTitle>
                        <Scrollbars style={{ height: 320 }}>
                            <ListItem onClick={dropuns}>
                                {children}
                            </ListItem>
                        </Scrollbars>
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
}

export default DropDownCustom;