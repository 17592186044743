import { createSlice, createSelector } from '@reduxjs/toolkit';

import { streamsStateChanged, streamDataPushed } from './SocketsSlice';
import { setCurrentInstrumentSymbol } from './InstrumentsSlice';

const dataState = {
  error: null,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
};

const initialState = {
  // keep all data streams
  // because they all on the same screen
  liveTrades: {
    sid: null,
    data: [],
    ...dataState,
  },
  orderBook: {
    sid: null,
    data: [],
    ...dataState,
  },
  activeOrders: {
    sid: null,
    data: [],
    ...dataState,
  },
  // Trading Screen → Orders History
  ordersHistory: {
    sid: null,
    data: [],
    count: 0,
    ...dataState,
    filters: {
      instrument: null,
      dateFrom: 'last_day',
      offset: 0,
      limit: 50,
    },
  },
};

const defaultResponseHandler = (
  data,
  payload,
  onNewDataCallback = null,
) => {
  if (typeof onNewDataCallback !== 'function') {
    onNewDataCallback = () => data.data.push(payload.d);
  }

  if (payload?.sig === 3) {
    // stream closed, clean data
    data.data = [];
    data.isLoading = true;
  } else if (payload?.sig === 1) {
    // skip item
    data.isLoading = false;
  } else if (payload?.d?.messageType === 'SnapshotEnd') {
    // skip item
    data.isLoading = false;
  } else if (payload?.d && payload?.sig !== 2) {
    // answer with new data
    onNewDataCallback();
  } else {
    // error or unexpected answer
    data.isError = true;
    data.isLoading = false;
    data.error = {
      data: {
        code: payload?.errorType,
        message: payload?.d.errorMessage || `Unexpected response from ${payload?.sid} SID`,
      },
    };
  }
};

const activeOrdersResponseHandler = (data, payload) => {
  defaultResponseHandler(data, payload, () => {
    let action = null;
    switch (payload?.d.messageType) {
      case 'ActiveOrder':
      case 'Added':
        action = 'push';
        break;
      case 'Cancelled':
        action = 'delete';
        break;
      case 'Executed':
        action = (payload?.d.remainingOpenQuantity > 0) ? 'upsert' : 'delete';
        break;
      case 'SnapshotEnd':
      case 'Rejected':
      default:
        // ignore
    }

    switch (action) {
      case 'delete':
        data.data = data.data.filter(({ orderId }) => orderId !== payload?.d.orderId);
        break;
      case 'upsert':
        const item = data.data.find(({ orderId }) => orderId === payload?.d.orderId);
        if (item) {
          const { messageType, orderId, ...props } = payload.d;
          return Object.assign(item, props);
        }
        data.data.push(payload.d);
        break;
      case 'push':
        data.data.push(payload.d);
        break;
      default:
        // ignore
    }
  });
};

/**
 * All dynamic data appeared at Trading Screen.
 * Live Trades Stream, Order Book Stream, Active Orders
 */
const slice = createSlice({
  name: 'trading',
  initialState,
  reducers: {
    orderPlaced(state, { payload }) {
      // socket middleware should handle it
    },
    orderCanceled(state, { payload }) {

    },
    dataFilterChanged(state, { payload }) {
      const { dataType, ...filters } = payload;
      switch (dataType) {
        case 'ordersHistory':
          Object.keys(filters).forEach(key => {
            state[dataType].filters[key] = filters[key];
          });
          break;
        default:
          // ignore
      }
    },
  },
  extraReducers: {
    [setCurrentInstrumentSymbol.toString()]: (state, { payload }) => {
      state.ordersHistory.filters.instrument = payload.symbol;
    },
    [streamsStateChanged.toString()]: (state, { payload }) => {
      // map just created stream to slice property
      // don't listen push actions from old stream anymore
      const { created } = payload;
      created.forEach(({ sliceDataKey, sid }) => {
        switch (sliceDataKey) {
          case 'trading.liveTrades':
            state.liveTrades.sid = sid;
            state.liveTrades.data = [];
            break;
          case 'trading.orderBook':
            state.orderBook.sid = sid;
            state.orderBook.data = [];
            break;
          case 'trading.activeOrders':
            state.activeOrders.sid = sid;
            state.activeOrders.data = [];
            break;
          case 'trading.ordersHistory':
            state.ordersHistory.sid = sid;
            state.ordersHistory.data = [];
            state.ordersHistory.count = 0;
            break;
          default:
            // ignore
        }
      });
    },
    [streamDataPushed.toString()]: (state, { payload }) => {
      let sliceData = null;
      switch (payload?.sid) {
        case null:
          break;
        case state.liveTrades.sid:
          sliceData = state.liveTrades;
          defaultResponseHandler(sliceData, payload, () => {
            // payload {d: [1199.91, 0, 1, …] }}
            // means SnapshotEnd and should be ignore
            if (payload?.d[1] > 0) {
              sliceData.data.push(payload.d);
            } else {
              sliceData.isLoading = false;
            }
          });
          break;
        case state.orderBook.sid:
          sliceData = state.orderBook;
          defaultResponseHandler(sliceData, payload, () => {
            sliceData.data = [payload.d];
          });
          break;
        case state.activeOrders.sid:
          sliceData = state.activeOrders;
          activeOrdersResponseHandler(sliceData, payload);
          break;
        case state.ordersHistory.sid:
          sliceData = state.ordersHistory;
          defaultResponseHandler(sliceData, payload, () => {
            sliceData.data = payload.d.orders;
            sliceData.count = payload.d.count;
          });
          break;
        default:
          // ignore
      }
    },
  },
});

export default slice.reducer;

export const {
  orderPlaced,
  orderCanceled,
  dataFilterChanged,
} = slice.actions;

export const selectLiveTrades = (state) => state.trading.liveTrades.data;
export const selectOrderBook = (state) => state.trading.orderBook.data;
export const selectInstrumentActiveOrders = (state) => state.trading.activeOrders.data;
export const selectInstrumentOrdersHistory = createSelector(
  (state) => state.trading.ordersHistory,
  ({ data, count, filters: { dateFrom, offset, limit } }) => ({
    data,
    count,
    dateFrom,
    currentPage: offset / limit + 1,
    totalPages: Math.ceil(count / limit),
    itemsPerPage: limit,
  }),
);

const sortAndCutCallback = (list, { limit = null, sortIndex = null, sortDir = 'asc'}) => {
  // sort then cut to limit
  let copy = list.concat();
  if (sortIndex) {
    copy.sort((a, b) => {
      const dir = sortDir === 'asc' ? 1 : -1;
      if (a?.[sortIndex] > b?.[sortIndex]) return 1 * dir;
      if (a?.[sortIndex] < b?.[sortIndex]) return -1 * dir;
      return 0;
    });
  }

  if (limit !== null) {
    copy = copy.slice(0, limit);
  }

  return copy;
};

export const makeSelectLiveTradesWithSort = ({ limit = null, sortIndex = null, sortDir = 'asc' }) => createSelector(
  selectLiveTrades,
  (trades) => sortAndCutCallback(trades, { limit, sortIndex, sortDir }),
);

export const makeSelectInstrumentActiveOrdersWithSort = ({ limit = null, sortIndex = null, sortDir = 'asc' }) => createSelector(
  selectInstrumentActiveOrders,
  (orders) => sortAndCutCallback(orders, { limit, sortIndex, sortDir }),
);
