import { createSlice, createSelector } from '@reduxjs/toolkit';

import { streamsStateChanged, streamDataPushed as socketStreamDataPushed } from './SocketsSlice';

const initialState = {
  currentInstrumentSymbol: null,
  data: [],
  error: null,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  streamSid: null,
};

const streamDataPushReducer = (state, { payload }) => {
  if (payload?.sig === 3) {
    // stream closed, clean data
    state.data = [];
    state.isLoading = true;
    return;
  }

  const index = state.data.findIndex(({ symbol }) => symbol === payload?.d.symbol );
  if (state.data[index]) {
    Object.assign(state.data[index], payload?.d);
  }
};

const slice = createSlice({
  name: 'instruments',
  initialState,
  reducers: {
    setCurrentInstrumentSymbol(state, { payload }) {
      state.currentInstrumentSymbol = payload.symbol;
    },
    setLoading(state, action) {
      state.isLoading = true;
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
    },
    setData(state, action) {
      state.data = action.payload.data;
    },
    streamDataPushed: streamDataPushReducer,
    setError(state, action) {
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
  extraReducers: {
    [streamsStateChanged.toString()]: (state, { payload }) => {
      // map just created stream to slice property
      // don't listen push actions from old stream anymore
      const { created } = payload;
      const stream = created.find(({ outputReceiverAction }) => outputReceiverAction === streamDataPushed.toString());
      if (stream) {
        state.streamSid = stream.sid;
      }
    },
    [socketStreamDataPushed.toString()]: (state, action) => {
      if (action.payload?.sid === state.streamSid) {
        streamDataPushReducer(state, action);
      }
    },
  },
});

export default slice.reducer;

export const {
  setCurrentInstrumentSymbol,
  setLoading,
  setData,
  streamDataPushed,
  setError,
} = slice.actions;

export const selectAllInstruments = (state) => state.instruments.data;

export const selectCurrentInstrumentSymbol = (state) => state.instruments.currentInstrumentSymbol;

export const makeSelectInstrumentsOrderBy = ({ field = 'symbols', dir = 'asc' })  => createSelector(
  (state) => state.instruments.data ?? [],
  (instruments) => {
    return instruments.concat().sort((a, b) => {
      if (a[field] > b[field]) return dir === 'asc' ? 1 : -1;
      if (a[field] < b[field]) return dir === 'asc' ? -1 : 1;
      return 0;
    });
  },
);

export const selectInstrumentsSymbols = createSelector(
  (state) => state.instruments.data,
  (data) => data.map(({ symbol }) => symbol),
);
