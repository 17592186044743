import React from "react";
import styled from "styled-components";

const PopupContainer = styled("div")`
    z-index: 1;
    width: 1080px;
    height: 400px;
    display: flex;
    position: absolute;
    align-item: canter;
    background: transparent;
    @media (max-width: 1080px) {
        width: 100vw;
    }
`;
const Popup = styled("div")`
    width: 446px;
    height: 222px;
    padding: 34px;
    margin: auto;
    border-radius: 4px;
    background: #262726;
    @media (max-width: 430px) {
        border: 1px solid #707070;
    }
`
const PopupButton = styled("div")`
    display: flex;
    margin-top: 58px;
    justify-content: end;
`;
const PopupH1 = styled("h1")`
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #F6F6F6;
`;
const PopupP = styled("p")`
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    padding-top: 24px;
    font-size: 14px;
    line-height: 16px;
    color: #C4C4C4;
`;
const No = styled("span")`
    display: flex;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    width: 72px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: 2px solid #707070;
    border-radius: 30px;
    height: 28px;
    cursor: pointer;
    &:hover{
        background: #0D0D0D;
    }
`;
const Yes = styled("span")`
    display: flex;
    background: ${props => props.bg};
    border-radius: 30px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 30px;
    color: #0D0D0D;
    width: 72px;
    height: 28px;
    cursor: pointer;
    &:hover{
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
linear-gradient(0deg, ${props => props.bg}, ${props => props.bg});
    }
`;

const PopupCancel = ({
    onYes,
    onNo,
}) => {
    return(
         <PopupContainer>
             <Popup>
                <PopupH1>Cancel Order</PopupH1>
                <PopupP>Do you want to cancel this order?</PopupP>
                <PopupButton>
                    <No onClick={onNo}>no</No>
                    <Yes onClick={onYes} bg={process.env.REACT_APP_BRAND_CTA_BG_Color}>yes</Yes>
                </PopupButton>
             </Popup>
         </PopupContainer>
    )
}

export default PopupCancel;