import React,{ useEffect, useMemo, useCallback }from "react";
import Calendar from 'react-calendar';
import styled from "styled-components";
import { DateTime } from "luxon";
import 'react-calendar/dist/Calendar.css';

import TifDropDown from './TifDropDown';

const ButtonBuy = styled("button")`
  background: ${props => props.bgc};
  border: 0;
  border-radius: 30px;
  color: ${props => props.color};
  width: 100%;
  padding: 12px 0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  &:hover:not([disabled]){
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
linear-gradient(0deg, ${props => props.bgc}, ${props => props.bgc});
  &:disabled{
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
linear-gradient(0deg, ${props => props.bgc}, ${props => props.bgc});
  }  
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const PlaceOrder = ({
    hookForm,
    onSubmit,
    isLoading = false,
    isDisabled = false,
}) => {
    const { handleSubmit, setValue, watch, register } = hookForm;
    const {
        orderType: watchOrderType,
        side: watchSide,
        quantity: watchQuantity,
        price: watchPrice,
        timeInForce: watchTif,
        timeInForceMarketOnly: watchTifMarketOnly,
        expiryDate: watchExpiryDate,
    } = watch();
    // disable form submit when inputs zero or negative
    const isInputEmpty = parseFloat(watchQuantity || 0) <= 0
        || (watchOrderType === 'Limit' && parseFloat(watchPrice || 0) <= 0);

    // Time in force select options
    // Limit order: GTC (default)|GTD|IOC|FOK
    // Market order: IOC (default)|FOK
    const tifOptions = useMemo(() => {
        return [ 'GTC',  'GTD', 'IOC',  'FOK' ]
            .filter((label) => watchOrderType === 'Limit' || ['IOC', 'FOK'].includes(label))
            .map((label) => ({ label, value: label }));
    }, [watchOrderType]);

    // current TIF value based on order type
    const tif = useMemo(
        () => (watchOrderType === 'Limit') ? watchTif : watchTifMarketOnly, [watchTif, watchTifMarketOnly, watchOrderType]
    );

    // use different fields each order type to keep old TIF value untouched
    const onTifSelect = useCallback(
        (v) => setValue((watchOrderType === 'Limit') ? 'timeInForce' : 'timeInForceMarketOnly', v), [watchOrderType, setValue]
    );

    // show calendar when TIF === GTD and order type is Limit
    const isCalendarVisible = watchTif === 'GTD' && watchOrderType === 'Limit';

    // calculate total, when order type is Market return null
    const total = useMemo(() => {
        if (watchOrderType !== 'Limit') return null;

        return watchQuantity * watchPrice;
    }, [watchOrderType, watchQuantity, watchPrice]);

    const minDate = useMemo(() => DateTime.now().toJSDate(), [watchTif]); // eslint-disable-line

    // when value cannot be applied to Market order type
    // reset IOC
    useEffect(() => {
        if (watchOrderType === 'market' && !['IOC', 'FOK'].includes(watchTifMarketOnly)) {
            setValue('watchTifMarketOnly', 'IOC');
        }
    }, [watchOrderType, watchTifMarketOnly, setValue]);

    // when GTD selected set today midnight as default date
    useEffect(() => {
        const expiredOrUndefined = watchExpiryDate instanceof Date === false
            || DateTime.fromJSDate(watchExpiryDate) < DateTime.now();
        if (watchTif === 'GTD' && expiredOrUndefined) {
            // today midnight
            setValue('expiryDate', DateTime.now().endOf('day').toJSDate());
        }
    }, [watchTif, watchExpiryDate, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="react-tabs" data-rttabs="true">
                <ul className="react-tabs__tab-list" role="tablist">
                    <div className="wrap_tab">
                        <li className="react-tabs__tab" role="tab">
                            <div
                                className={`buy_title_${watchSide === 'Buy' ? 'show' : 'hide'}`}
                                onClick={() => setValue('side', 'Buy')}
                            >
                                <h1>BUY</h1>
                            </div>
                        </li>
                        <li className="react-tabs__tab" role="tab">
                            <div
                                className={`sell_title_${watchSide === 'Buy' ? 'hide' : 'show'}`}
                                onClick={() => setValue('side', 'Sell')}
                            >
                                <h1>SELL</h1>
                            </div>
                        </li>
                    </div>
                    <div className="react-tabs__tab-panel react-tabs__tab-panel--selected" role="tabpanel">
                        <div className="tab_limit">
                            <div className="react-tabs" data-rttabs="true">
                                 <ul className="react-tabs__tab-list" role="tablist">
                                    <li className="react-tabs__tab react-tabs__tab--selected" role="tab">
                                        <h3
                                            onClick={() => setValue('orderType', 'Limit')}
                                            style={{ color: watchOrderType === 'Limit' ? '#EBEBEB' : '#707070'}}>
                                            Limit
                                        </h3>
                                    </li>
                                    <li className="react-tabs__tab react-tabs__tab--selected" role="tab">
                                        <h3
                                            onClick={() => setValue('orderType', 'Market')}
                                            style={{ color: watchOrderType === 'Limit' ? '#707070' : '#EBEBEB'}}
                                        >
                                            Market
                                        </h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="wrap_field">
                                {/* remove price when order type is market */}
                                {watchOrderType === 'Limit' && (
                                    <div className="Amount">
                                        <label>Price</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            // decimals don't pass validation
                                            // without 0.01 step
                                            // ref @link https://stackoverflow.com/a/34057860
                                            step=".01"
                                            min="0"
                                            {...register('price')}
                                        />
                                    </div>
                                )}
                                <div className="Price">
                                    <label>Amount</label>
                                    <input
                                        type="number"
                                        placeholder="0"
                                        // decimals don't pass validation
                                        // without 0.01 step
                                        // ref @link https://stackoverflow.com/a/34057860
                                        step=".01"
                                        min="0"
                                        {...register('quantity')}
                                    />
                                </div>
                                <div className="TIF">
                                    <label>TIF</label>
                                    <TifDropDown
                                        selected={tif}
                                        options={tifOptions}
                                        onChildrenClick={onTifSelect}
                                    >
                                    </TifDropDown>
                                </div>
                            </div>
                            {isCalendarVisible && (
                                <>
                                    <div className="wrap_second_tab">
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.8 2.13626H14.4C15.2837 2.13626 16 2.85261 16 3.73626V14.4029C16 15.2866 15.2837 16.0029 14.4 16.0029H1.6C0.716345 16.0029 0 15.2866 0 14.4029V3.73626C0 2.85261 0.716345 2.13626 1.6 2.13626H3.2V0.00292969H4.26667V2.13626H11.7333V0.00292969H12.8V2.13626ZM6.4 8.53626H3.2V7.4696H6.4V8.53626ZM12.8 7.4696H9.6V8.53626H12.8V7.4696ZM6.4 11.7363H3.2V10.6696H6.4V11.7363ZM9.6 11.7363H12.8V10.6696H9.6V11.7363Z" fill="#F6F6F6"/>
                                        </svg>
                                        <h3>Expiry date & Time</h3>
                                    </div>
                                    <div className="place_order_calendar">
                                        <Calendar
                                            onChange={(v) => {
                                                const expiryDate = DateTime.fromJSDate(v).endOf('day').toJSDate();
                                                setValue('expiryDate', expiryDate);
                                            }}
                                            value={watchExpiryDate}
                                            minDate={minDate}
                                        />
                                    </div>
                                    <div className="time_wrap">

                                    </div>
                                </>
                            )}
                            <div className="total_wrap">
                                {/* remove total when order type is market */}
                                {total !== null && (
                                    <span>Total:<span className="total">{total.toFixed(2)}</span></span>
                                )}
                            </div>
                            <ButtonBuy
                                bgc={process.env.REACT_APP_BRAND_CTA_BG_Color}
                                color={process.env.REACT_APP_BRAND_CTA_Text_Color}
                                disabled={isLoading || isDisabled || isInputEmpty}
                                type="submit"
                            >
                                {watchSide}
                            </ButtonBuy>
                        </div>
                    </div>
                </ul>
            </div>
        </form>
    );
};

export default PlaceOrder;