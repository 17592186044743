import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ex_Logo from "./img/ex_Logo.svg"
const term_link = process.env.REACT_APP_TERMS_URL;

const TermConditional = styled('a')`
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
    transition: color .3s ease;
    &:hover{
      color: ${props => props.bgl}
    }
`
const Footer = () => {
  return (
    <footer>
      <div className="footer_bottom">
        <ul>
          <li>
            <Link to="/" className="ex_link">
              Powered by <img src={ex_Logo} alt="" />
            </Link>
          </li>
          <li>
            <TermConditional href={term_link} target="_blank" bgl={process.env.REACT_APP_BRAND_CTA_BG_Color}>
              Terms & Conditions
            </TermConditional>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
