import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';

import MainDashboard from "./MainDashbooard/MainDashboard";
import OredersTrades from "./OredersTrades/OredersTrades";
import TrandingScreen from "./TrandingScreen/TrandingScreen";
import PopupCancel from './HelpComponent/PopupCancel';
import Notification from './HelpComponent/Notification';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { selectLatestNotification, selectModal } from './store/selectors';
import { dataSubscriptionsChanged } from './store/slices/SocketsSlice';
import { orderCanceled } from './store/slices/TradingSlice';
import { modalClosed, MODAL_CANCEL_ORDER_TYPE } from './store/slices/UiSlice';

function Home() {
    const dispatch = useDispatch();
    const latestNotification = useSelector(selectLatestNotification);
    const modal = useSelector(selectModal);

    useEffect(()=>{
        // on component unmount
        return () => dispatch(dataSubscriptionsChanged([]));
    }, []);

    const popupCancel = (
        <PopupCancel
            onNo={() => dispatch(modalClosed())}
            onYes={() => {
                dispatch(modalClosed());
                dispatch(orderCanceled({
                    orderId: modal?.params.orderId,
                    instrument: modal?.params.instrument,
                }));
            }}
        />
    );
    const isCancelModalShown = MODAL_CANCEL_ORDER_TYPE === modal?.type;

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <MainDashboard>
                            {/* we don't expect popups at that page
                            but it might be there some day */}
                            {isCancelModalShown && popupCancel}
                        </MainDashboard>
                    }
                />
                <Route
                    path="/orders-trades"
                    element={
                        <OredersTrades>
                            {isCancelModalShown && popupCancel}
                        </OredersTrades>
                    }
                />
                <Route
                    path="/asset/:id"
                    element={
                        <TrandingScreen>
                            {isCancelModalShown && popupCancel}
                        </TrandingScreen>
                    }/>
            </Routes>
            <Notification
                messNotification={latestNotification}
            />
        </BrowserRouter>
    );
}
export default Home;