import React, { useState } from "react";
import styled from "styled-components";

const DropDownContRows = styled("div")`
    width: 105px;
    position: relative;
    margin-right: 10px;
    font-style: initial;
    display: inline-block;
`;

const DropDownHeaderRows = styled("div")`
    width: 100%;
    color: #fff;
    font-size: 14px;
    padding: 0px 2px 0px 12px;
    margin-right: 10px;
    cursor: pointer;
        &:hover{
        background: #0D0D0D;
    }
    &:after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L6.8 6.8L11.6 2' stroke='%23C4C4C4' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E");
        width: 14px;
        height: 8px;
        margin: 0 8px;
        display: inline-block;
        background-repeat: no-repeat;
    }
    &:after:hover{
        background: red;
    }
`;

const DropDownListContainer = styled("div")`
    top: -105px;
    width: 90px;
    left: 10px;
    z-index: 1;
    position: absolute;
`;

const DropDownList = styled("ul")`
  padding: 8px;
  margin: 0;
  background: #0D0D0D;
  border: 1px solid #414244;
  box-sizing: border-box;
  border-radius: 4px;
`;

const ListItem = styled("li")`
  list-style: none;
  color: ${props => {
    if(props.activeitem === '25 rows'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else if(props.activeitem === '50 rows'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else if(props.activeitem === '100 rows'){
        return process.env.REACT_APP_BRAND_CTA_BG_Color
    }else {
        return '#EBEBEB';
    }
}}; 
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 8px;
  cursor: pointer;
  &:hover{
    background: #262726;
    padding: 7px;
    border: 1px solid #414244;
    border-radius: 3px;
  }
`;

const DropDownRows = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    return(
        <DropDownContRows>
            <DropDownHeaderRows onClick={toggling}>{value+" rows"}</DropDownHeaderRows>
            {isOpen && (
                <DropDownListContainer>
                    <DropDownList>
                        {['25 rows', '50 rows', '100 rows'].map((val)=>{
                            const valres = value+" rows";
                            return (<ListItem
                                onClick={(e) =>{onChange(e.target.dataset.rows);toggling()}}
                                activeitem={valres === val ? valres : ''}
                                data-rows={val}
                            >
                                {val}
                            </ListItem>);
                        })}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContRows>
    )
}

export default DropDownRows;