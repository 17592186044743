import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider as ReduxProvider } from "react-redux";
import { Settings } from "luxon";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en"; // locale-data for en

import App from "./App";
import store from "./store/store"

//const domain = process.env.REACT_APP_AUTH0_DOMAIN;
//const clientid = process.env.REACT_APP_AUTH0_CLIENT_ID;

const domain = process.env.REACT_APP_AUTH0_DOMAIN_PROD;
const clientid = process.env.REACT_APP_AUTH0_CLIENT_ID_MAINPROD;
Settings.defaultLocale = "en-US";

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientid}
    redirectUri={window.location.origin}
    leeway={300}
    audience="exberry-trading"
    scope="oms-history:* oms-account:* oms-trading:*"
  >
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </Auth0Provider>,
  document.getElementById("root")
);