import React, {useState} from 'react';
import styled from "styled-components";

const DropDateFormContainer = styled("div")`
    width: 130px;
    flex-grow: 1;
    position: relative;
    justify-content: flex-end;
    display: inline-flex;
`;

const DropDataHeader = styled("div")`
    color: #fff;
    font-size: 14px;
    padding-top: 0px;
    cursor: pointer;
        &:hover{
        background: #0D0D0D;
    }
    &:after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L6.8 6.8L11.6 2' stroke='%23C4C4C4' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E");
        width: 14px;
        height: 8px;
        margin: 0 8px;
        display: inline-block;
        background-repeat: no-repeat;
    }
`;

const DropDataListContainer = styled("div")`
    top: 30px;
    width: 110px;
    z-index: 1;
    position: absolute;
`;

const DropDownDataList = styled("ul")`
  padding: 8px;
  margin: 0;
  background: #0D0D0D;
  border: 1px solid #414244;
  box-sizing: border-box;
  border-radius: 4px;
`;

const ListItem = styled("li")`
  list-style: none;
  color: ${props => {
      if(props.activeitem === 'last_day'){
          return process.env.REACT_APP_BRAND_CTA_BG_Color
      }else if(props.activeitem === 'last_week'){
          return process.env.REACT_APP_BRAND_CTA_BG_Color
      }else if(props.activeitem === 'last_30_days'){
          return process.env.REACT_APP_BRAND_CTA_BG_Color
      }else {
          return '#EBEBEB';
      }
    }}; 
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 8px;
  cursor: pointer;
  &:hover{
    background: #262726;
    padding: 7px;
    border: 1px solid #414244;
    border-radius: 3px;
  }
`;

const DateFromFilter = ({ onChange, value }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    const label = (itm) => {
        if(itm === 'last_day'){return 'Last Day'}
        if(itm === 'last_week'){return 'Last Week'}
        if(itm === 'last_30_days'){return 'Last 30 Day'}
    }
    return (
        <DropDateFormContainer>
            <DropDataHeader
                id={`date-from-filter`}
                name={`date-from-filter`}
                onClick={toggling}>
                {(['last_day', 'last_week', 'last_30_days'].includes(value)) ? label(value): 'custom'}
            </DropDataHeader>
            {isOpen && (
                <DropDataListContainer>
                    <DropDownDataList>
                        {['last_day', 'last_week', 'last_30_days'].map((val)=>{
                            return (<ListItem
                                onClick={(e) =>{onChange(e.target.dataset.datevalue);toggling()}}
                                activeitem={value === val ? value : ''}
                                data-datevalue={val}
                            >
                                {label(val)}
                            </ListItem>);
                        })}
                        <ListItem>Custom</ListItem>
                    </DropDownDataList>
                </DropDataListContainer>
            )}
        </DropDateFormContainer>
    );
};

export default DateFromFilter;
