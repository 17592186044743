import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";

import Home from "./Home";
import LoginButton from "./Login";
import { successfulLogin } from './store/slices/AuthSlice';
import "./App.css";


function App() {
    const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently();
                    dispatch(successfulLogin({ token, username: user.name }));
                } catch (e) {
                    console.error(e);
                }
            })();
        }
        //Change Brand site
        document.title = process.env.REACT_APP_BRAND_TITLE;
        let list = document.querySelectorAll('link[rel="icon"], link[rel="shortcut icon"]');
        list.forEach(function(element) {
            element.setAttribute('href', process.env.REACT_APP_BRAND_FAVICON);
        });
    }, [user]);

    var loginButton;
    if (isAuthenticated) {
        loginButton = <Home/>;
    }else if(isLoading === false && isAuthenticated === false){
        loginButton = <LoginButton />;
    }

    return <>{loginButton}</>;
}

export default App;