import React from "react";
import {Link} from "react-router-dom";

const Asset = ({ instruments }) => (
  <>
    {instruments.map(({ symbol, askPrice, lastPrice, bidPrice, imageUrls = [] }, index) => (
      <Link to={`/asset/${symbol}`} key={index}>
        <div className="traiders_frames_item" key={index}>
          <div className="bg-grad">
            <div className="frame_item_image">
              <img src={imageUrls[0] ?? null} alt="logo" />
            </div>
            <div className="frame_item_content">
              <div className="top_strip">
                <span>{symbol}</span>
              </div>
              <div className="bottom_strip">
                <div className="Bid_bs bottom_strip_item">
                  <span className="bs_label">Bid</span>
                  <div className="bs_label_content">
                    <span className="bs_triangle gren"></span>
                    <span className="bs_value">
                      {bidPrice ? bidPrice.toFixed(2) : ""}
                    </span>
                  </div>
                </div>
                <div className="Ask_bs bottom_strip_item">
                  <span className="bs_label">Ask</span>
                  <div className="bs_label_content">
                    <span className="bs_triangle gren"></span>
                    <span className="bs_value">
                      {askPrice ? askPrice.toFixed(2) : ""}
                    </span>
                  </div>
                </div>
                <div className="LastPrice_bs bottom_strip_item">
                  <span className="bs_label">Last Price</span>
                  <div className="bs_label_content">
                    <span className="bs_triangle red"></span>
                    <span className="bs_value">
                      {lastPrice ? lastPrice.toFixed(2) : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    ))}
  </> 
);

export default Asset;
