import React from "react";
import EmptyIcon from "../EmptyIcon/EmptyIcon";
import styled from "styled-components";

const Separator = styled("span")`
    content: "|";
    width: 1px;
    margin: 0 5px;
    height: 15px;
    background: #707070;
    display: inline-block;
    vertical-align: text-top;
`

const LiveTrades = ({ liveTrades }) => {
    const items = liveTrades.map((item, index) => {
        let d = new Date(item[3]);
        let options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour12: true,
        };

        let str = d.toLocaleString("en-US", options);
        let time = d.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        let strSplit = str.split(",");

        return (
            <tr key={index}>
                <td>{item[1]}</td>
                <td>{item[0]}</td>
                <td>{strSplit[0] + "," + strSplit[1]}<Separator></Separator>{time}</td>
            </tr>
        );
    });

    return<>{items}</>
}

export default LiveTrades;