import { createSlice } from '@reduxjs/toolkit';

import { streamsStateChanged, streamDataPushed as socketStreamDataPushed } from './SocketsSlice';

const initialState = {
  data: [],
  streamSid: null,
  error: null,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
};

const streamDataPushReducer = (state, { payload }) => {
  if (payload?.sig === 3) {
    // stream closed, clean data
    state.data = [];
    state.isLoading = true;
    return;
  }

  switch (payload?.d.messageType) {
    case 'Balance':
      const { messageType, ...assetProps } = payload.d;
      const index = state.data.findIndex(({ asset }) => asset === assetProps.asset );
      if (state.data[index]) {
        state.data[index] = assetProps;
      } else {
        state.data.push(assetProps);
      }
      break;
    case 'SnapshotEnd':
      state.isFetching = false;
      state.isLoading = false;
      state.isSuccess = true;
      break;
    default:
      // first response to reset data
      state.data = [];
  }
};

const slice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    streamDataPushed: streamDataPushReducer,
    setError(state, action) {
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
    },
  },
  extraReducers: {
    [streamsStateChanged.toString()]: (state, { payload }) => {
      // map just created stream to slice property
      // don't listen push actions from old stream anymore
      const { created } = payload;
      const stream = created.find(({ outputReceiverAction }) => outputReceiverAction === streamDataPushed.toString());
      if (stream) {
        state.streamSid = stream.sid;
      }
    },
    [socketStreamDataPushed.toString()]: (state, action) => {
      if (action.payload?.sid === state.streamSid) {
        streamDataPushReducer(state, action);
      }
    },
  },
});

export default slice.reducer;

export const {
  streamDataPushed,
  setError,
} = slice.actions;

export const selectBalance = state => state.balance.data;
