import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import EmptyIcon from "../../TrandingScreen/EmptyIcon/EmptyIcon";

const OredersOrdersHistory = ({ 
    ordersHistory,
}) => {

    let ordersHistoryItems;
    const bgh = process.env.REACT_APP_BRAND_CTA_BG_Color;

    if(ordersHistory.length !== 0) {
        ordersHistoryItems = ordersHistory.map((item, index) => {
            let d = new Date(item.createdAt);
            let options = {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour12: true,
            };
            let str = d.toLocaleString("en-US", options);
            let time = d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
            let strSplit = str.split(",");
            let percentage =
                item.filledQuantity / item.quantity * 100;
                //((item.quantity - item.filledQuantity) * 100) / item.quantity;
            let char = percentage + " 100";

            return (
                <tr key={index}>
                    <td>{strSplit[0] + "," + strSplit[1] + " | " + time}</td>
                    <td>
                        <span className="instr">{item.instrument}</span>
                    </td>
                    <td>
                        <span className={item.side}>{item.side}</span>
                    </td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.filledQuantity}</td>
                    <td>{item.filledPrice}</td>
                    <td>{item.cancelledQuantity}</td>
                    <td>
                        <svg viewBox="0 0 32 32">
                            <circle
                                cx="50%"
                                cy="50%"
                                r="16"
                                stroke={bgh}
                                strokeDasharray={char}
                                strokeDashoffset="0"
                            ></circle>
                        </svg>
                    </td>
                </tr>
            );
        })
    }

    return(
        <div className="tab_wrapp">
            <div className="content_table">
                <Scrollbars style={{ height: 'calc(70vh - 50px)'}}
                    renderTrackVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, width: 4,
                            right: 0,
                            bottom: 2,
                            top: 2,
                            borderRadius: 3 }}/>
                    }
                    renderView={props => <div {...props} className="view"/>}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Instrument</th>
                            <th>Side</th>
                            <th>Price</th>
                            <th>Original Amt</th>
                            <th>Filled Amt</th>
                            <th>Filled Price</th>
                            <th>Canceled Amt</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {ordersHistoryItems !== undefined ? ordersHistoryItems : <EmptyIcon></EmptyIcon>}
                        </tbody>
                    </table>
                </Scrollbars>
            </div>
        </div>
    );
}

export default OredersOrdersHistory;